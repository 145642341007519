import ecoImage from '../images/eco.webp';
import bg2 from '../images/sustainableBanner3.webp'
import visionMissionImage from '../images/visionMission.webp';

const AboutPage = () => {
    return (
        <div className="flex flex-col items-center justify-center">
            <div className="flex w-full">
                <img alt='Eko İnovasyon' loading='lazy' className='w-full' src={ecoImage} />
            </div>
            <div className="text-base lg:text-lg breadcrumbs px-5 lg:px-20 pt-8 pb-8 w-full">
                <ul>
                    <li><a href='/'>Anasayfa</a></li>
                    <li>Hakkımızda</li>
                </ul>
            </div>
            <div className="flex bg-slate-50">
                <div className="flex flex-col pb-12 gap-10 lg:gap-5">
                    <div className="flex h-1/2 lg:mx-10 lg:gap-10 flex-col-reverse lg:flex-row">
                        <div className="flex flex-col justify-center px-5 lg:w-1/2 lg:px-10 gap-2">
                            <h1 className="text-3xl">Biz Kimiz</h1>
                            <p className="text-justify">Biz, sürdürülebilirliği bilimsel verilerle şekillendiren bir danışmanlık şirketiyiz. Uzmanlık alanımız, karbon ayak izini detaylı olarak hesaplamak ve bu bilgileri, Yeşil Mutabakat (Green Deal) ve Yaşam Döngüsü Değerlendirmesi (Life Cycle Assessment - LCA) ilkeleri ışığında stratejiler geliştirmek. Karbonsuzlaştırma yolculuğunuzda, sizi sadece rehberlik etmekle kalmayıp, aynı zamanda çevre dostu dönüşümünüzü hızlandırmak için eğitimler ve danışmanlık hizmetleri sunuyoruz.</p>
                        </div>
                        <div className="flex lg:w-1/2 justify-center items-center">
                            <img alt='görsel' loading='lazy' className='h-4/6 w-auto' src={bg2} />
                        </div>
                    </div>

                    <div className="flex h-1/2 lg:mx-10 lg:gap-10 flex-col lg:flex-row">
                        <div className="flex lg:w-1/2 justify-center items-center">
                            <img alt='vizyon' loading='lazy' className='h-4/6 w-auto' src={visionMissionImage} />
                        </div>
                        <div className="flex flex-col justify-center px-5 lg:w-1/2 lg:px-10 gap-2">
                            <p className="text-justify"><span className='font-bold'>Vizyonumuz:</span> Dünya genelinde karbon nötrlüğüne giden yolda öncü bir güç olmayı ve yeşil yükü hafifletmeyi amaçlıyoruz. Bilgi ve doğrulamalarla donatılmış, eğitim ve danışmanlık hizmetlerimizle, bireylerin ve şirketlerin sürdürülebilirliğe olan katkılarını maksimize ederken, bir yandan da ekonomik büyümenin yeşil potansiyelini ortaya çıkarıyoruz.<br /><br /><span className='font-bold'>Misyonumuz:</span> Çağdaş dünyanın karşı karşıya olduğu en büyük meydan okumaları, bilimsel yaklaşımlar ve yenilikçi çözümlerle aşmayı hedefliyoruz. Karbon hesaplamaları ve sürdürülebilirlik danışmanlığı yoluyla, işletmelerin ve bireylerin çevresel etkilerini anlamalarını ve iyileştirmelerini sağlıyor, gezegenimizin geleceğine katkıda bulunuyoruz.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutPage;