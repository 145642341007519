import reportImage from "../images/report.webp";
import img2 from "../images/sustainableBanner1.webp";
import stageImage from "../images/Stage.webp";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import BeenhereOutlinedIcon from "@mui/icons-material/BeenhereOutlined";
import { useRef } from "react";
import { useIsVisible } from "../utils/VisibilityRef";
import { useParams } from "react-router-dom";
import RightNavigationCarbonSolutions from "../components/RightNavigationCarbonSolutions";

const CarbonManagement = () => {
  const ref1 = useRef();
  const isVisible1 = useIsVisible(ref1);

  return (
    <div
      ref={ref1}
      className="flex flex-col items-center justify-center text-base leading-7"
    >
      <div className="flex w-full">
        <img alt="rapor" loading="lazy" className="w-full" src={reportImage} />
      </div>
      <div
        className={`text-base lg:text-lg breadcrumbs px-5 lg:px-20 pt-8 pb-8 w-full`}
      >
        <ul>
          <li>
            <a href="/">Anasayfa</a>
          </li>
          <li>
            <a href="/hizmetlerimiz">Hizmetlerimiz</a>
          </li>
          <li>Karbon Yönetimi</li>
        </ul>
      </div>
      <div
        className={`flex bg-slate-50 transition-opacity ease-in ${
          isVisible1 ? "opacity-100" : "opacity-0"
        }`}
        style={{ transitionDuration: "1250ms" }}
      >
        <div className="flex flex-col p-5 lg:p-12 gap-10 lg:gap-20">
          <h1 className="text-center text-4xl">
            Karbon Yönetimi: İklim Değişikliğiyle Mücadelede Stratejik
            Yaklaşımlar
          </h1>

          <div className="flex flex-col-reverse lg:flex-row w-full gap-5">
            <div className="flex flex-col gap-20">
              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "4000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 flex-col-reverse lg:flex-row">
                    <div className="flex flex-col justify-center lg:w-2/3 gap-2">
                      <p className="text-justify">
                        Karbon Yönetimi, işletmelerin ve kuruluşların karbon
                        ayak izlerini ölçmeleri, yönetmeleri ve azaltmaları
                        sürecini kapsayan bir danışmanlık hizmetidir. Bu hizmet,
                        karbon emisyonlarının etkin bir şekilde kontrol altına
                        alınmasını ve sürdürülebilirlik hedeflerine ulaşılmasını
                        sağlamak amacıyla tasarlanmıştır. Uzman danışmanlar,
                        karbon emisyonlarını azaltma stratejileri geliştirir,
                        karbon ticareti ve offset projeleri gibi çözümleri
                        entegre eder ve işletmelerin çevresel regülasyonlara
                        uyumunu sağlar.
                        <br />
                        <br />
                        <span>
                          Bizden{" "}
                          <span className="font-bold">
                            Karbon Yönetimi Hizmeti{" "}
                          </span>
                          Almak için
                        </span>{" "}
                        <a href="/gorusme-ayarla" className="font-bold link">
                          Buradan
                        </a>{" "}
                        Hemen İletişime geçin.
                      </p>
                    </div>
                    {/* <div className="flex lg:w-1/2 justify-center items-center">
                      <img alt="görsel" loading='lazy' className=" w-auto rounded-box" src={img1} />
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "5000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 flex-col lg:flex-row">
                    <div className="flex lg:w-1/2 justify-center items-center">
                      <img
                        alt="görsel"
                        loading="lazy"
                        className=" w-auto rounded-box"
                        src={img2}
                      />
                    </div>
                    <div className="flex flex-col justify-center lg:w-1/2 gap-8">
                      <h1 className="text-2xl font-bold">
                        Danışmanlık Hizmetlerinin Kapsamı ve Amacı
                      </h1>
                      <p>
                        Karbon Yönetimi danışmanlığı, işletmelere aşağıdaki
                        konularda destek sağlar:
                      </p>
                      <p className="text-justify">
                        <span className="text-lg font-bold">
                          Karbon Ayak İzi Hesaplama:
                        </span>{" "}
                        İşletmenin toplam karbon emisyonlarının detaylı analizi.
                        <br />
                        <br />
                        <span className="text-lg font-bold">
                          Azaltma Stratejileri Geliştirme:
                        </span>{" "}
                        Enerji verimliliği, yenilenebilir enerji kullanımı ve
                        süreç optimizasyonu gibi emisyon azaltma yöntemleri.
                        <br />
                        <br />
                        <span className="text-lg font-bold">
                          Karbon Ticareti ve Offsetleme:
                        </span>{" "}
                        Karbon kredisi alım-satımı ve karbon offset projeleri
                        ile emisyon azaltma.
                        <br />
                        <br />
                        <span className="text-lg font-bold">
                          Raporlama ve İletişim:
                        </span>{" "}
                        Sürdürülebilirlik raporları hazırlama ve paydaşlarla
                        etkili iletişim kurma.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "6000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 flex-col-reverse lg:flex-row">
                    <div className="flex flex-col justify-center lg:w-2/3 gap-2">
                      <p className="text-justify">
                        <span className="text-2xl font-bold">
                          Uygulama ve Yararları
                        </span>
                        <br />
                        <br />
                        Karbon Yönetimi, işletmelerin enerji tüketimini ve
                        operasyonel maliyetlerini düşürürken, çevresel
                        performanslarını artırmalarına yardımcı olur. Bu hizmet,
                        işletmelerin piyasa itibarını ve yatırımcı çekiciliğini
                        güçlendirir, aynı zamanda çevresel düzenlemelere ve
                        uluslararası iklim değişikliği anlaşmalarına uyumu
                        kolaylaştırır.
                      </p>
                    </div>
                    {/* <div className="flex lg:w-1/2 justify-center items-center">
                      <img alt="görsel" loading='lazy' className="w-auto rounded-box" src={img1} />
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "6000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 lg:flex-row">
                    <div className="flex flex-col justify-center lg:w-2/3 gap-2">
                      <p className="text-justify">
                        <span className="text-2xl font-bold">
                          Sosyal ve Çevresel Etkiler
                        </span>
                        <br />
                        <br />
                        Karbon Yönetimi, küresel iklim değişikliğiyle mücadelede
                        işletmelerin rolünü güçlendirir. Bu hizmet, sera gazı
                        emisyonlarının azaltılmasına katkıda bulunur ve çevre
                        üzerindeki genel baskıyı düşürür. Ayrıca, toplumda
                        sürdürülebilirlik bilincini artırır ve çevre dostu iş
                        uygulamalarının benimsenmesini teşvik eder.
                        <br />
                        <br />
                        Karbon Yönetimi Danışmanlığı, işletmelerin ve
                        kuruluşların karbon ayak izlerini azaltma ve çevresel
                        sürdürülebilirlik hedeflerine ulaşma yolculuklarında
                        kritik bir rehberdir. Bu hizmet, stratejik rehberlik ve
                        pratik çözümler sunarak, işletmelerin daha yeşil ve daha
                        sorumlu bir şekilde faaliyet göstermelerine yardımcı
                        olur.
                      </p>
                    </div>
                    {/* <div className="flex lg:w-1/2 justify-center items-center">
                      <img alt="görsel" loading='lazy' className="w-auto rounded-box" src={img1} />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <RightNavigationCarbonSolutions />
          </div>
        </div>
      </div>
      <div
        className={`flex flex-col px-5 lg:px-20 py-16 items-center gap-5 lg:gap-10 transition-opacity ease-in ${
          isVisible1 ? "opacity-100" : "opacity-0"
        }`}
        style={{ transitionDuration: "1250ms" }}
      >
        <h1 className="text-xl text-center lg:text-4xl font-bold">
          Hizmetlerimizin Size Sunduğu Fırsatlar
        </h1>
        <div className="flex flex-col lg:flex-row justify-between items-center text-white gap-5">
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <BusinessOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Uzmanlık</h1>
            <p>
              Alanında uzman ekibimizle, karbon ayak izi hesaplama ve raporlama
              konusunda kapsamlı hizmet sunuyoruz.
            </p>
          </div>
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <ReceiptLongOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Şeffaflık</h1>
            <p>
              Çevresel performansınızı net bir şekilde ortaya koyarak,
              şeffaflığı ve hesap verilebilirliği artırıyoruz.
            </p>
          </div>
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <LanguageOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Stratejik Planlama</h1>
            <p>
              Emisyon azaltımı için stratejik planlama yaparak,
              sürdürülebilirlik hedeflerinize ulaşmanıza yardımcı oluyoruz.
            </p>
          </div>
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <BeenhereOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Rekabet Avantajı</h1>
            <p>
              Sürdürülebilirlik odaklı çalışmalarınızla, sektörünüzde fark
              yaratmanıza ve rekabet avantajı elde etmenize olanak sağlıyoruz.
            </p>
          </div>
        </div>
      </div>
      <div
        className={`lg:w-9/12 transition-opacity ease-in ${
          isVisible1 ? "opacity-100" : "opacity-0"
        }`}
        style={{ transitionDuration: "1250ms" }}
      >
        <img loading="lazy" src={stageImage} />
      </div>
    </div>
  );
};

const CarbonOffset = () => {
  const ref1 = useRef();
  const isVisible1 = useIsVisible(ref1);

  return (
    <div
      ref={ref1}
      className="flex flex-col items-center justify-center text-base leading-7"
    >
      <div className="flex w-full">
        <img alt="rapor" loading="lazy" className="w-full" src={reportImage} />
      </div>
      <div
        className={`text-base lg:text-lg breadcrumbs px-5 lg:px-20 pt-8 pb-8 w-full`}
      >
        <ul>
          <li>
            <a href="/">Anasayfa</a>
          </li>
          <li>
            <a href="/hizmetlerimiz">Hizmetlerimiz</a>
          </li>
          <li>Karbon Ofset</li>
        </ul>
      </div>
      <div
        className={`flex bg-slate-50 transition-opacity ease-in ${
          isVisible1 ? "opacity-100" : "opacity-0"
        }`}
        style={{ transitionDuration: "1250ms" }}
      >
        <div className="flex flex-col p-5 lg:p-12 gap-10 lg:gap-20">
          <h1 className="text-center text-4xl">
            Karbon Ofset: İklim Değişikliğiyle Mücadelede Aktif Katılım
          </h1>

          <div className="flex flex-col-reverse lg:flex-row w-full gap-5">
            <div className="flex flex-col gap-20">
              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "4000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 flex-col-reverse lg:flex-row">
                    <div className="flex flex-col justify-center lg:w-2/3 gap-2">
                      <p className="text-justify">
                        Karbon Ofset, işletmelerin ve bireylerin kendi karbon
                        emisyonlarını dengelemek için, karbon emisyonlarını
                        azaltıcı projelere yatırım yapmalarını sağlayan bir
                        mekanizmadır. Bu projeler genellikle yenilenebilir
                        enerji, ormancılık veya enerji verimliliği projeleri
                        olabilir ve dünya genelinde uygulanabilir. Karbon Ofset
                        danışmanlığı, şirketlere en uygun ofset projelerini
                        seçmeleri, yatırımlarını yönetmeleri ve karbon
                        nötrlüğüne ulaşmaları konusunda rehberlik eder.
                        <br />
                        <br />
                        <span>
                          Bizden{" "}
                          <span className="font-bold">
                            Karbon Ofset Hizmeti{" "}
                          </span>
                          Almak için
                        </span>{" "}
                        <a href="/gorusme-ayarla" className="font-bold link">
                          Buradan
                        </a>{" "}
                        Hemen İletişime geçin.
                      </p>
                    </div>
                    {/* <div className="flex lg:w-1/2 justify-center items-center">
                      <img alt="görsel" loading='lazy' className=" w-auto rounded-box" src={img1} />
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "5000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 flex-col lg:flex-row">
                    <div className="flex lg:w-1/2 justify-center items-center">
                      <img
                        alt="görsel"
                        loading="lazy"
                        className=" w-auto rounded-box"
                        src={img2}
                      />
                    </div>
                    <div className="flex flex-col justify-center lg:w-1/2 gap-8">
                      <h1 className="text-2xl font-bold">
                        Danışmanlık Hizmetlerinin Kapsamı ve Amacı
                      </h1>
                      <p>
                        Karbon Ofset danışmanlığı, şirketlere ve bireylere
                        aşağıdaki konularda destek sağlar:
                      </p>
                      <p className="text-justify">
                        <span className="text-lg font-bold">
                          Karbon Ayak İzi Hesaplama:
                        </span>{" "}
                        Toplam karbon emisyonlarının belirlenmesi.
                        <br />
                        <br />
                        <span className="text-lg font-bold">
                          Ofset Projelerinin Seçimi:
                        </span>{" "}
                        Çevresel bütünlüğü yüksek ve sürdürülebilir etkisi
                        kanıtlanmış projelerin seçilmesi.
                        <br />
                        <br />
                        <span className="text-lg font-bold">
                          Yatırım Yönetimi:
                        </span>{" "}
                        Ofset projelerine yatırım yapma süreçlerinin
                        yönetilmesi.
                        <br />
                        <br />
                        <span className="text-lg font-bold">
                          Sertifikasyon ve Raporlama:
                        </span>{" "}
                        Ofset projelerinden elde edilen sonuçların
                        sertifikalandırılması ve raporlanması.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "6000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 flex-col-reverse lg:flex-row">
                    <div className="flex flex-col justify-center lg:w-2/3 gap-2">
                      <p className="text-justify">
                        <span className="text-2xl font-bold">
                          Uygulama ve Yararları
                        </span>
                        <br />
                        <br />
                        Karbon Ofset, işletmelerin çevresel sorumluluklarını
                        yerine getirmelerine ve sürdürülebilirlik hedeflerine
                        ulaşmalarına yardımcı olur. Ayrıca, işletmelerin
                        çevresel düzenlemelere uyumunu ve pazarlardaki itibarını
                        güçlendirir. Karbon ofseti, şirketlerin ve bireylerin
                        çevresel etkilerini aktif bir şekilde azaltmalarını
                        sağlayarak, iklim değişikliğiyle mücadelede önemli bir
                        role sahiptir.
                      </p>
                    </div>
                    {/* <div className="flex lg:w-1/2 justify-center items-center">
                      <img alt="görsel" loading='lazy' className="w-auto rounded-box" src={img1} />
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "6000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 lg:flex-row">
                    <div className="flex flex-col justify-center lg:w-2/3 gap-2">
                      <p className="text-justify">
                        <span className="text-2xl font-bold">
                          Sosyal ve Çevresel Etkiler
                        </span>
                        <br />
                        <br />
                        Karbon Ofset, karbon emisyonlarının global azaltılması
                        çabalarına katkı sağlar ve özellikle gelişmekte olan
                        ülkelerde sürdürülebilir kalkınma projelerini destekler.
                        Bu projeler, yerel topluluklara ekonomik faydalar
                        sağlamanın yanı sıra, biyolojik çeşitliliği koruma ve
                        çevresel sürdürülebilirliği artırma gibi ek avantajlar
                        sunar.
                        <br />
                        <br />
                        Karbon Ofset Danışmanlığı, işletmelerin ve bireylerin
                        çevresel ayak izlerini azaltma çabalarında kritik bir
                        destek sağlar. Bu hizmet, karbon nötrlüğüne ulaşma
                        yolunda etkili stratejiler ve çözümler sunarak, daha
                        yeşil ve sürdürülebilir bir geleceğe katkıda bulunur.
                      </p>
                    </div>
                    {/* <div className="flex lg:w-1/2 justify-center items-center">
                      <img alt="görsel" loading='lazy' className="w-auto rounded-box" src={img1} />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <RightNavigationCarbonSolutions />
          </div>
        </div>
      </div>
      <div
        className={`flex flex-col px-5 lg:px-20 py-16 items-center gap-5 lg:gap-10 transition-opacity ease-in ${
          isVisible1 ? "opacity-100" : "opacity-0"
        }`}
        style={{ transitionDuration: "1250ms" }}
      >
        <h1 className="text-xl text-center lg:text-4xl font-bold">
          Hizmetlerimizin Size Sunduğu Fırsatlar
        </h1>
        <div className="flex flex-col lg:flex-row justify-between items-center text-white gap-5">
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <BusinessOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Uzmanlık</h1>
            <p>
              Alanında uzman ekibimizle, karbon ayak izi hesaplama ve raporlama
              konusunda kapsamlı hizmet sunuyoruz.
            </p>
          </div>
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <ReceiptLongOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Şeffaflık</h1>
            <p>
              Çevresel performansınızı net bir şekilde ortaya koyarak,
              şeffaflığı ve hesap verilebilirliği artırıyoruz.
            </p>
          </div>
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <LanguageOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Stratejik Planlama</h1>
            <p>
              Emisyon azaltımı için stratejik planlama yaparak,
              sürdürülebilirlik hedeflerinize ulaşmanıza yardımcı oluyoruz.
            </p>
          </div>
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <BeenhereOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Rekabet Avantajı</h1>
            <p>
              Sürdürülebilirlik odaklı çalışmalarınızla, sektörünüzde fark
              yaratmanıza ve rekabet avantajı elde etmenize olanak sağlıyoruz.
            </p>
          </div>
        </div>
      </div>
      <div
        className={`lg:w-9/12 transition-opacity ease-in ${
          isVisible1 ? "opacity-100" : "opacity-0"
        }`}
        style={{ transitionDuration: "1250ms" }}
      >
        <img loading="lazy" src={stageImage} />
      </div>
    </div>
  );
};

const GreenhouseGasReduction = () => {
  const ref1 = useRef();
  const isVisible1 = useIsVisible(ref1);

  return (
    <div
      ref={ref1}
      className="flex flex-col items-center justify-center text-base leading-7"
    >
      <div className="flex w-full">
        <img alt="rapor" loading="lazy" className="w-full" src={reportImage} />
      </div>
      <div
        className={`text-base lg:text-lg breadcrumbs px-5 lg:px-20 pt-8 pb-8 w-full`}
      >
        <ul>
          <li>
            <a href="/">Anasayfa</a>
          </li>
          <li>
            <a href="/hizmetlerimiz">Hizmetlerimiz</a>
          </li>
          <li>Sera Gazı Azaltım ve Uzaklaştırma Projelerinin Hazırlanması</li>
        </ul>
      </div>
      <div
        className={`flex bg-slate-50 transition-opacity ease-in ${
          isVisible1 ? "opacity-100" : "opacity-0"
        }`}
        style={{ transitionDuration: "1250ms" }}
      >
        <div className="flex flex-col p-5 lg:p-12 gap-10 lg:gap-20">
          <h1 className="text-center text-4xl">
            Sera Gazı Azaltım ve Uzaklaştırma Projelerinin Hazırlanması: İklim
            Değişikliğiyle Mücadelede Etkili Stratejiler
          </h1>

          <div className="flex flex-col-reverse lg:flex-row w-full gap-5">
            <div className="flex flex-col gap-20">
              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "4000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 flex-col-reverse lg:flex-row">
                    <div className="flex flex-col justify-center lg:w-2/3 gap-2">
                      <p className="text-justify">
                        Sera Gazı Azaltım ve Uzaklaştırma Projelerinin
                        Hazırlanması, işletmelerin ve kuruluşların sera gazı
                        emisyonlarını azaltma veya atmosferden karbon çıkarma
                        hedeflerine ulaşmalarına yardımcı olan stratejik bir
                        danışmanlık hizmetidir. Bu hizmet, yenilenebilir enerji
                        projeleri, enerji verimliliği iyileştirmeleri,
                        ormancılık projeleri ve karbon yakalama teknolojileri
                        gibi çeşitli azaltım ve uzaklaştırma yöntemlerini
                        içerir. Uzman danışmanlar, projelerin planlanması,
                        finansmanı ve uygulanmasında rehberlik ederler.
                        <br />
                        <br />
                        <span>
                          Bizden{" "}
                          <span className="font-bold">
                            Sera Gazı Azaltım ve Uzaklaştırma Projelerinin
                            Hazırlanması Hizmeti{" "}
                          </span>
                          Almak için
                        </span>{" "}
                        <a href="/gorusme-ayarla" className="font-bold link">
                          Buradan
                        </a>{" "}
                        Hemen İletişime geçin.
                      </p>
                    </div>
                    {/* <div className="flex lg:w-1/2 justify-center items-center">
                      <img alt="görsel" loading='lazy' className=" w-auto rounded-box" src={img1} />
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "5000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 flex-col lg:flex-row">
                    <div className="flex lg:w-1/2 justify-center items-center">
                      <img
                        alt="görsel"
                        loading="lazy"
                        className=" w-auto rounded-box"
                        src={img2}
                      />
                    </div>
                    <div className="flex flex-col justify-center lg:w-1/2 gap-8">
                      <h1 className="text-2xl font-bold">
                        Danışmanlık Hizmetlerinin Kapsamı ve Amacı
                      </h1>
                      <p>
                        Sera Gazı Azaltım ve Uzaklaştırma Projelerinin
                        Hazırlanması danışmanlığı, şu hizmetleri içerir:
                      </p>
                      <p className="text-justify">
                        <span className="text-lg font-bold">
                          Proje Planlama ve Tasarımı:
                        </span>{" "}
                        Emisyon azaltımı ve karbon uzaklaştırma projelerinin
                        tasarımı ve planlanması.
                        <br />
                        <br />
                        <span className="text-lg font-bold">
                          Teknoloji Seçimi ve Uygulama:
                        </span>{" "}
                        Proje hedeflerine uygun en etkili teknolojilerin
                        seçilmesi ve uygulanması.
                        <br />
                        <br />
                        <span className="text-lg font-bold">
                          Finansman ve Hibeler:
                        </span>{" "}
                        Projeler için finansman kaynaklarının belirlenmesi ve
                        hibe fırsatlarının değerlendirilmesi.
                        <br />
                        <br />
                        <span className="text-lg font-bold">
                          Raporlama ve Doğrulama:
                        </span>{" "}
                        Projelerin çevresel etkilerinin raporlanması ve bağımsız
                        doğrulama süreçlerinin yönetilmesi.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "6000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 flex-col-reverse lg:flex-row">
                    <div className="flex flex-col justify-center lg:w-2/3 gap-2">
                      <p className="text-justify">
                        <span className="text-2xl font-bold">
                          Uygulama ve Yararları
                        </span>
                        <br />
                        <br />
                        Sera Gazı Azaltım ve Uzaklaştırma Projelerinin
                        Hazırlanması, işletmelerin çevresel regülasyonlara uyum
                        sağlamalarını ve sürdürülebilirlik hedeflerine
                        ulaşmalarını kolaylaştırır. Bu projeler, işletmelerin
                        karbon ayak izlerini azaltmalarına ve potansiyel olarak
                        karbon kredisi pazarlarından gelir elde etmelerine
                        olanak tanır. Ayrıca, kurumsal itibarları güçlenir ve
                        yeşil yatırımcılar ile müşteriler nezdinde çekicilikleri
                        artar.
                      </p>
                    </div>
                    {/* <div className="flex lg:w-1/2 justify-center items-center">
                      <img alt="görsel" loading='lazy' className="w-auto rounded-box" src={img1} />
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "6000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 lg:flex-row">
                    <div className="flex flex-col justify-center lg:w-2/3 gap-2">
                      <p className="text-justify">
                        <span className="text-2xl font-bold">
                          Sosyal ve Çevresel Etkiler
                        </span>
                        <br />
                        <br />
                        Sera Gazı Azaltım ve Uzaklaştırma Projeleri, küresel
                        iklim değişikliğiyle mücadelede kritik bir role
                        sahiptir. Bu projeler, sera gazı emisyonlarının
                        azaltılmasına ve atmosferdeki karbonun
                        uzaklaştırılmasına doğrudan katkıda bulunur, böylece
                        çevresel sürdürülebilirliği destekler ve ekosistem
                        sağlığını iyileştirir.
                        <br />
                        <br />
                        Sera Gazı Azaltım ve Uzaklaştırma Projelerinin
                        Hazırlanması Danışmanlığı, işletmelerin ve kuruluşların
                        iklim değişikliğiyle mücadelede aktif rol almasını
                        sağlar. Bu hizmet, sürdürülebilir ve yenilikçi çözümler
                        sunarak, daha yeşil bir geleceğe geçiş yapılmasına
                        yardımcı olur.
                      </p>
                    </div>
                    {/* <div className="flex lg:w-1/2 justify-center items-center">
                      <img alt="görsel" loading='lazy' className="w-auto rounded-box" src={img1} />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <RightNavigationCarbonSolutions />
          </div>
        </div>
      </div>
      <div
        className={`flex flex-col px-5 lg:px-20 py-16 items-center gap-5 lg:gap-10 transition-opacity ease-in ${
          isVisible1 ? "opacity-100" : "opacity-0"
        }`}
        style={{ transitionDuration: "1250ms" }}
      >
        <h1 className="text-xl text-center lg:text-4xl font-bold">
          Hizmetlerimizin Size Sunduğu Fırsatlar
        </h1>
        <div className="flex flex-col lg:flex-row justify-between items-center text-white gap-5">
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <BusinessOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Uzmanlık</h1>
            <p>
              Alanında uzman ekibimizle, karbon ayak izi hesaplama ve raporlama
              konusunda kapsamlı hizmet sunuyoruz.
            </p>
          </div>
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <ReceiptLongOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Şeffaflık</h1>
            <p>
              Çevresel performansınızı net bir şekilde ortaya koyarak,
              şeffaflığı ve hesap verilebilirliği artırıyoruz.
            </p>
          </div>
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <LanguageOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Stratejik Planlama</h1>
            <p>
              Emisyon azaltımı için stratejik planlama yaparak,
              sürdürülebilirlik hedeflerinize ulaşmanıza yardımcı oluyoruz.
            </p>
          </div>
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <BeenhereOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Rekabet Avantajı</h1>
            <p>
              Sürdürülebilirlik odaklı çalışmalarınızla, sektörünüzde fark
              yaratmanıza ve rekabet avantajı elde etmenize olanak sağlıyoruz.
            </p>
          </div>
        </div>
      </div>
      <div
        className={`lg:w-9/12 transition-opacity ease-in ${
          isVisible1 ? "opacity-100" : "opacity-0"
        }`}
        style={{ transitionDuration: "1250ms" }}
      >
        <img loading="lazy" src={stageImage} />
      </div>
    </div>
  );
};

const CarbonSolutionsDetailPage = () => {
  const { id } = useParams();

  const PickPage = () => {
    switch (id) {
      case "karbon-cozumleri":
        return <CarbonManagement />;
      case "karbon-ofset":
        return <CarbonOffset />;
      case "sera-gazi-azaltim-ve-uzaklastirma-projelerinin-hazirlanmasi":
        return <GreenhouseGasReduction />;
    }
  };

  return <PickPage />;
};

export default CarbonSolutionsDetailPage;
