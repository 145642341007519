import zeroWasteImage from '../images/zeroWaste.webp';
import greenOfficeImage from '../images/ofis.webp';
import iso1Image from '../images/ISO1.webp';

const Blog = () => {
    return (
        <div className="w-full flex flex-col justify-center py-10 items-center gap-8">
            <div className="flex flex-col w-full justify-center items-center">
                <h1 className="text-2xl font-bold">Blog</h1>
                <a href="/blog" className="link">Daha fazla</a>
            </div>
            <div className="carousel w-11/12 px-4 py-10 space-x-4 rounded-box flex lg:justify-center">
                <div className="carousel-item transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105" style={{ transitionDuration: '400ms' }}>
                    <div className="w-72 lg:w-96 bg-base-100 shadow-xl rounded-box flex flex-col justify-between">
                        <figure><a href='blog/sifir-atik-gelecek-nesillere-temiz-bir-dunya-birakmak' className='hover:cursor-pointer'><img loading='lazy' className='rounded-t-box' src={zeroWasteImage} alt="Sıfır Atık" /></a></figure>
                        <div className="card-body">
                            <h2 className="card-title">Sıfır Atık: Gelecek Nesillere Temiz Bir Dünya Bırakmak</h2>
                            <p>Sıfır Atık, israfın önlenmesini ve kaynakların daha verimli kullanılmasını teşvik eden, atık oluşumunun engellenmesi veya minimize edilmesi ve oluşan atıkların da geri dönüştürülmesi veya yeniden kullanılması üzerine kurulu bir atık yönetim felsefesidir.</p>
                            <div className="card-actions justify-end">
                                <a href='blog/sifir-atik-gelecek-nesillere-temiz-bir-dunya-birakmak' className="link">Devamını oku</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="carousel-item transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105" style={{ transitionDuration: '400ms' }}>
                    <div className="w-72 lg:w-96 bg-base-100 shadow-xl rounded-box flex flex-col justify-between">
                        <figure><a href='blog/daha-yesil-ve-surdurulebilir-ofisler-icin-oneriler' className='hover:cursor-pointer'><img loading='lazy' className='rounded-t-box' src={greenOfficeImage} alt="Yeşil Ofis" /></a></figure>
                        <div className="card-body">
                            <h2 className="card-title">Daha Yeşil ve Sürdürülebilir Ofisler için Öneriler</h2>
                            <p>Ofisler, hem çalışanlar hem de çevre için önemli bir etkiye sahiptir. Ofislerde kullanılan enerji, su ve malzemeler, önemli miktarda atık ve kirliliğe neden olabilir. Daha yeşil ve sürdürülebilir ofisler oluşturmak, bu etkiyi azaltmak ve daha sağlıklı bir çalışma ortamı yaratmak için önemlidir.</p>
                            <div className="card-actions justify-end">
                                <a href='blog/daha-yesil-ve-surdurulebilir-ofisler-icin-oneriler' className="link">Devamını oku</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="carousel-item transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105" style={{ transitionDuration: '400ms' }}>
                    <div className="w-72 lg:w-96 bg-base-100 shadow-xl rounded-box flex flex-col justify-between">
                        <figure><a href='/blog/iso-14064-1-standardi' className='hover:cursor-pointer'><img loading='lazy' className='rounded-t-box' src={iso1Image} alt="ISO" /></a></figure>
                        <div className="card-body">
                            <h2 className="card-title">ISO 14064-1 Standardı</h2>
                            <p className='flex-1'>İklim değişikliği ile mücadelede sera gazı emisyonlarını azaltmak, günümüzün en önemli küresel önceliklerinden biridir. ISO 14064-1 standardı, sera gazı emisyonlarının kuruluş seviyesinde hesaplanması ve raporlanması için bir çerçeve sunan önemli bir standarttır.</p>
                            <div className="card-actions flex justify-end">
                                <a href='/blog/iso-14064-1-standardi' className="link">Devamını oku</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Blog;