import susImage from '../images/sustainable.webp';
import isoImage from '../images/ISO.webp';
import greenCollarImage from '../images/greenCollar.webp';
import img1 from '../images/sustainableBanner2.webp';
import img2 from '../images/sustainableBanner5.webp';
import img3 from '../images/decarbonization3.webp';

const WhoWeAre = () => {
    return (
        <div className="flex bg-slate-50">
            <div className="flex flex-col py-24 gap-16">
                <div className="flex mx-4 lg:mx-10 lg:gap-10 flex-col-reverse lg:flex-row bg-slate-200 py-10 rounded-box">
                    <div className="flex flex-col justify-center lg:w-2/3 px-10 gap-2">
                        <h1 className="text-3xl font-bold">Şeffaflık ve Netlik ile Sürdürülebilirliğe Adım Atın</h1>
                        <p className="text-justify lg:text-xl">Çevre performansınızı şeffaf bir şekilde ortaya koyan detaylı sera gazı envanteri çalışmalarımız, sürdürülebilir hedeflerinize ulaşmanız için net bir başlangıç noktası sağlar.</p>
                    </div>
                </div>

                <div className='grid grid-cols-1 lg:grid-cols-3 gap-5 py-8 px-5 lg:px-20'>
                    <div className="flex flex-col bg-base-100 shadow-xl rounded-box transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105" style={{ transitionDuration: '400ms' }}>
                        <figure><a className='hover:cursor-pointer'><img loading='lazy' className='rounded-t-box' src={susImage} alt="Sürdürülebilirlik" /></a></figure>
                        <div className="card-body">
                            <h2 className="card-title">Sürdürülebilirlik</h2>
                            <p className='text-justify'>Geleceğe yatırım yapmanın en etkili yolu bilinçli olmaktan geçer. Sürdürülebilirlik eğitimleriyle, bireylerin ve kurumların çevresel bilincini artırmayı ve bu bilinci harekete geçirilebilir stratejilerle buluşturmayı hedefliyoruz.</p>
                            <div className="card-actions justify-end my-2">
                                <a className="link">Devamını oku</a>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col bg-base-100 shadow-xl rounded-box transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105" style={{ transitionDuration: '400ms' }}>
                        <figure><a className='hover:cursor-pointer'><img loading='lazy' className='rounded-t-box' src={isoImage} alt="ISO" /></a></figure>
                        <div className="card-body">
                            <h2 className="card-title">ISO 14064-1 Sera Gazı</h2>
                            <p className='text-justify'>ISO 14064-1 standardı, sera gazı (GHG) envanterlerinin hazırlanması, raporlanması ve doğrulanması için bir çerçeve sağlayan uluslararası bir standarttır. Bu standart, kuruluşların sera gazı emisyonlarını ve giderimlerini kapsamlı bir şekilde belgelemelerine, yönetmelerine ve raporlamalarına olanak tanır.</p>
                            <div className="card-actions justify-end my-2">
                                <a className="link">Devamını oku</a>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col bg-base-100 shadow-xl rounded-box transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105" style={{ transitionDuration: '400ms' }}>
                        <figure><a className='hover:cursor-pointer'><img loading='lazy' className='rounded-t-box' src={greenCollarImage} alt="Yeşil Yaka" /></a></figure>
                        <div className="card-body">
                            <h2 className="card-title">Yeşil Yaka Hazırlama</h2>
                            <p className='text-justify'>Yeşil yaka iş gücü hazırlığı, sürdürülebilirlik ve çevre dostu endüstrilerde çalışacak bireyleri eğitim ve beceri geliştirme yoluyla donatmayı hedefler. Bu süreç, yenilenebilir enerji, enerji verimliliği, sürdürülebilir tarım, su yönetimi ve atık yönetimi gibi alanlarda nitelikli çalışanların yetiştirilmesini içerir.</p>
                            <div className="card-actions justify-end my-2">
                                <a className="link">Devamını oku</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex h-1/3 lg:h-1/5 mx-4 lg:mx-10 lg:gap-10 flex-col lg:flex-row lg:justify-start bg-slate-200 py-10 rounded-box">
                    <div className="flex flex-col justify-center lg:w-2/3 px-10 gap-2">
                        <h1 className="text-3xl font-bold">Sürdürülebilirlik Yolculuğunuzda Derinlemesine Bilgi ve Destek</h1>
                        <p className="text-justify lg:text-xl">Kapsamlı eğitim programlarımızla, sürdürülebilirliği derinlemesine anlamanızı ve çevresel sorumluluklarınızı etkin bir şekilde yerine getirmenizi destekliyoruz.</p>
                    </div>
                </div>

                <div className='grid grid-cols-1 lg:grid-cols-3 gap-5 py-8 px-5 lg:px-20'>
                    <div className="flex flex-col bg-base-100 shadow-xl rounded-box">
                        <figure><img loading='lazy' className='rounded-t-box' src={img1} alt="sürdürülebilirlik" /></figure>
                        <div className="card-body">
                            <h2 className="card-title"><br /></h2>
                            <p className='text-justify'></p>
                            {/* <div className="card-actions justify-end my-2">
                                <a href='blog/1' className="link">Devamını oku</a>
                            </div> */}
                        </div>
                    </div>

                    <div className="flex flex-col bg-base-100 shadow-xl rounded-box">
                        <figure><img loading='lazy' className='rounded-t-box' src={img2} alt="sürdürülebilirlik" /></figure>
                        <div className="card-body">
                            <h2 className="card-title"></h2>
                            <p className='text-justify'></p>
                            {/* <div className="card-actions justify-end my-2">
                                <a className="link">Devamını oku</a>
                            </div> */}
                        </div>
                    </div>

                    <div className="flex flex-col bg-base-100 shadow-xl rounded-box">
                        <figure><img loading='lazy' className='rounded-t-box' src={img3} alt="sürdürülebilirlik" /></figure>
                        <div className="card-body">
                            <h2 className="card-title"></h2>
                            <p className='text-justify'></p>
                            {/* <div className="card-actions justify-end my-2">
                                <a className="link">Devamını oku</a>
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className="flex h-1/3 lg:h-1/5 mx-4 lg:mx-10 lg:gap-10 flex-col-reverse lg:flex-row  bg-slate-200 py-10 rounded-box">
                    <div className="flex flex-col justify-center lg:w-2/3 px-10 gap-2">
                        <h1 className="text-3xl font-bold">Yeşil Büyüme: Özelleştirilmiş Stratejiler</h1>
                        <p className="text-justify lg:text-xl">Size özgü karbonsuzlaştırma stratejilerimizle, çevresel ayak izinizi azaltmanın yanı sıra, yeşil büyüme ve inovasyon fırsatlarını da keşfetmenize yardımcı oluyoruz.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhoWeAre;