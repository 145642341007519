import reportImage from "../images/report.webp";
import img2 from "../images/sustainableBanner2.webp";
import stageImage from "../images/Stage.webp";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import BeenhereOutlinedIcon from "@mui/icons-material/BeenhereOutlined";
import { useRef } from "react";
import { useIsVisible } from "../utils/VisibilityRef";
import { useParams } from "react-router-dom";
import RightNavigationInovation from "../components/RightNavigationInovation";

const ReusableEnergySystemDesign = () => {
  const ref1 = useRef();
  const isVisible1 = useIsVisible(ref1);

  return (
    <div
      ref={ref1}
      className="flex flex-col items-center justify-center text-base leading-7"
    >
      <div className="flex w-full">
        <img alt="rapor" loading="lazy" className="w-full" src={reportImage} />
      </div>
      <div
        className={`text-base lg:text-lg breadcrumbs px-5 lg:px-20 pt-8 pb-8 w-full`}
      >
        <ul>
          <li>
            <a href="/">Anasayfa</a>
          </li>
          <li>
            <a href="/hizmetlerimiz">Hizmetlerimiz</a>
          </li>
          <li>Yenilenebilir Enerji Sistemleri Tasarımı</li>
        </ul>
      </div>
      <div
        className={`flex bg-slate-50 transition-opacity ease-in ${
          isVisible1 ? "opacity-100" : "opacity-0"
        }`}
        style={{ transitionDuration: "1250ms" }}
      >
        <div className="flex flex-col p-5 lg:p-12 gap-10 lg:gap-20">
          <h1 className="text-center text-4xl">
            Yenilenebilir Enerji Sistemleri Tasarımı: Sürdürülebilir Enerji
            Çözümleri Geliştirme
          </h1>

          <div className="flex flex-col-reverse lg:flex-row w-full gap-5">
            <div className="flex flex-col gap-20">
              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "4000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 flex-col-reverse lg:flex-row">
                    <div className="flex flex-col justify-center lg:w-2/3 gap-2">
                      <p className="text-justify">
                        Yenilenebilir Enerji Sistemleri Tasarımı, kuruluşlara ve
                        bireylere çevre dostu enerji kaynaklarından maksimum
                        fayda sağlamak üzere özelleştirilmiş enerji çözümleri
                        sunan bir hizmettir. Bu hizmet, güneş, rüzgar,
                        hidroelektrik ve biyokütle gibi yenilenebilir enerji
                        kaynaklarının entegrasyonu üzerine odaklanır. Uzman
                        mühendisler ve tasarımcılar tarafından sunulan bu
                        hizmet, sürdürülebilir ve verimli enerji sistemlerinin
                        planlanması, kurulumu ve bakımı süreçlerini kapsar.
                        <br />
                        <br />
                        <span>
                          Bizden{" "}
                          <span className="font-bold">
                            Yenilenebilir Enerji Sistemleri Tasarımı Hizmeti{" "}
                          </span>
                          Almak için
                        </span>{" "}
                        <a href="/gorusme-ayarla" className="font-bold link">
                          Buradan
                        </a>{" "}
                        Hemen İletişime geçin.
                      </p>
                    </div>
                    {/* <div className="flex lg:w-1/2 justify-center items-center">
                      <img alt="görsel" loading='lazy' className=" w-auto rounded-box" src={img1} />
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "5000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 flex-col lg:flex-row">
                    <div className="flex lg:w-1/2 justify-center items-center">
                      <img
                        alt="görsel"
                        loading="lazy"
                        className=" w-auto rounded-box"
                        src={img2}
                      />
                    </div>
                    <div className="flex flex-col justify-center lg:w-1/2 gap-8">
                      <h1 className="text-2xl font-bold">
                        Hizmetlerin Kapsamı ve Amacı
                      </h1>
                      <p>
                        Yenilenebilir Enerji Sistemleri Tasarımı, aşağıdaki
                        alanlarda hizmetler sunar:
                      </p>
                      <p className="text-justify">
                        <span className="text-lg font-bold">
                          Enerji İhtiyaç Analizi:
                        </span>{" "}
                        Kullanıcı ihtiyaçlarına göre enerji tüketim profilinin
                        belirlenmesi.
                        <br />
                        <br />
                        <span className="text-lg font-bold">
                          Sistem Tasarımı ve Mühendislik:
                        </span>{" "}
                        Enerji verimliliği yüksek, maliyet etkin ve çevre dostu
                        sistemlerin tasarlanması.
                        <br />
                        <br />
                        <span className="text-lg font-bold">
                          Projelendirme ve Uygulama:
                        </span>{" "}
                        Sistem komponentlerinin seçimi, montajı ve entegrasyonu.
                        <br />
                        <br />
                        <span className="text-lg font-bold">
                          İzleme ve Bakım:
                        </span>{" "}
                        Sistemlerin düzenli olarak izlenmesi ve gerektiğinde
                        bakım ve onarım hizmetlerinin sağlanması.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "6000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 flex-col-reverse lg:flex-row">
                    <div className="flex flex-col justify-center lg:w-2/3 gap-2">
                      <p className="text-justify">
                        <span className="text-2xl font-bold">
                          Uygulama ve Yararları
                        </span>
                        <br />
                        <br />
                        Yenilenebilir Enerji Sistemleri Tasarımı, enerji
                        maliyetlerinde önemli tasarruflar sağlarken, enerji
                        tüketiminin çevresel etkilerini azaltır. Yenilenebilir
                        enerji kullanımı, kuruluşların karbon ayak izini
                        düşürmelerine ve sürdürülebilirlik hedeflerine
                        ulaşmalarına yardımcı olur. Ayrıca, bu sistemler, enerji
                        güvenliği sağlar ve enerji kaynaklarının
                        çeşitlendirilmesine olanak tanır.
                      </p>
                    </div>
                    {/* <div className="flex lg:w-1/2 justify-center items-center">
                      <img alt="görsel" loading='lazy' className="w-auto rounded-box" src={img1} />
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "6000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 lg:flex-row">
                    <div className="flex flex-col justify-center lg:w-2/3 gap-2">
                      <p className="text-justify">
                        <span className="text-2xl font-bold">
                          Sosyal ve Çevresel Etkiler
                        </span>
                        <br />
                        <br />
                        Yenilenebilir Enerji Sistemleri Tasarımı, fosil
                        yakıtların kullanımını azaltarak küresel iklim
                        değişikliğiyle mücadeleye katkıda bulunur. Bu hizmet,
                        yenilenebilir enerji kaynaklarının benimsenmesini teşvik
                        eder ve çevresel sürdürülebilirliği artırır. Ayrıca,
                        yerel ekonomilerin desteklenmesine ve enerji üretiminde
                        yerel kaynakların kullanılmasına yardımcı olur.
                        <br />
                        <br />
                        Yenilenebilir Enerji Sistemleri Tasarımı, enerji
                        üretiminde yenilikçi ve sürdürülebilir yaklaşımlar
                        sunarak, enerji ihtiyaçlarını çevre dostu yollarla
                        karşılamayı amaçlar. Bu hizmet, kuruluşların ve
                        bireylerin enerji bağımsızlıklarını artırırken, daha
                        yeşil ve daha sürdürülebilir bir geleceğe katkıda
                        bulunur.
                      </p>
                    </div>
                    {/* <div className="flex lg:w-1/2 justify-center items-center">
                      <img alt="görsel" loading='lazy' className="w-auto rounded-box" src={img1} />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <RightNavigationInovation />
          </div>
        </div>
      </div>
      <div
        className={`flex flex-col px-5 lg:px-20 py-16 items-center gap-5 lg:gap-10 transition-opacity ease-in ${
          isVisible1 ? "opacity-100" : "opacity-0"
        }`}
        style={{ transitionDuration: "1250ms" }}
      >
        <h1 className="text-xl text-center lg:text-4xl font-bold">
          Hizmetlerimizin Size Sunduğu Fırsatlar
        </h1>
        <div className="flex flex-col lg:flex-row justify-between items-center text-white gap-5">
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <BusinessOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Uzmanlık</h1>
            <p>
              Alanında uzman ekibimizle, karbon ayak izi hesaplama ve raporlama
              konusunda kapsamlı hizmet sunuyoruz.
            </p>
          </div>
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <ReceiptLongOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Şeffaflık</h1>
            <p>
              Çevresel performansınızı net bir şekilde ortaya koyarak,
              şeffaflığı ve hesap verilebilirliği artırıyoruz.
            </p>
          </div>
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <LanguageOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Stratejik Planlama</h1>
            <p>
              Emisyon azaltımı için stratejik planlama yaparak,
              sürdürülebilirlik hedeflerinize ulaşmanıza yardımcı oluyoruz.
            </p>
          </div>
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <BeenhereOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Rekabet Avantajı</h1>
            <p>
              Sürdürülebilirlik odaklı çalışmalarınızla, sektörünüzde fark
              yaratmanıza ve rekabet avantajı elde etmenize olanak sağlıyoruz.
            </p>
          </div>
        </div>
      </div>
      <div
        className={`lg:w-9/12 transition-opacity ease-in ${
          isVisible1 ? "opacity-100" : "opacity-0"
        }`}
        style={{ transitionDuration: "1250ms" }}
      >
        <img loading="lazy" src={stageImage} />
      </div>
    </div>
  );
};

const ProccessEfficiencyAndOptimization = () => {
  const ref1 = useRef();
  const isVisible1 = useIsVisible(ref1);

  return (
    <div
      ref={ref1}
      className="flex flex-col items-center justify-center text-base leading-7"
    >
      <div className="flex w-full">
        <img alt="rapor" loading="lazy" className="w-full" src={reportImage} />
      </div>
      <div
        className={`text-base lg:text-lg breadcrumbs px-5 lg:px-20 pt-8 pb-8 w-full`}
      >
        <ul>
          <li>
            <a href="/">Anasayfa</a>
          </li>
          <li>
            <a href="/hizmetlerimiz">Hizmetlerimiz</a>
          </li>
          <li>Proses Verimliliği ve Optimizasyonu</li>
        </ul>
      </div>
      <div
        className={`flex bg-slate-50 transition-opacity ease-in ${
          isVisible1 ? "opacity-100" : "opacity-0"
        }`}
        style={{ transitionDuration: "1250ms" }}
      >
        <div className="flex flex-col p-5 lg:p-12 gap-10 lg:gap-20">
          <h1 className="text-center text-4xl">
            Proses Verimliliği ve Optimizasyonu: Operasyonel Mükemmelliğe Ulaşma
          </h1>

          <div className="flex flex-col-reverse lg:flex-row w-full gap-5">
            <div className="flex flex-col gap-20">
              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "4000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 flex-col-reverse lg:flex-row">
                    <div className="flex flex-col justify-center lg:w-2/3 gap-2">
                      <p className="text-justify">
                        Proses Verimliliği ve Optimizasyonu, işletmelerin üretim
                        ve iş süreçlerini en iyi şekilde iyileştirerek,
                        maliyetleri düşürme, ürün kalitesini artırma ve genel
                        verimliliği yükseltme hedeflerini destekleyen stratejik
                        bir danışmanlık hizmetidir. Bu hizmet, süreçlerin daha
                        akıcı ve etkin bir şekilde işlemesini sağlamak için
                        süreç analizi, iyileştirme önerileri ve yenilikçi
                        çözümlerin uygulanmasını içerir. Uzman danışmanlar,
                        işletmelerin süreçlerini baştan sona değerlendirerek,
                        israfı azaltmalarına ve operasyonel mükemmelliği
                        sağlamalarına yardımcı olur.
                        <br />
                        <br />
                        <span>
                          Bizden{" "}
                          <span className="font-bold">
                            Proses Verimliliği ve Optimizasyonu Hizmeti{" "}
                          </span>
                          Almak için
                        </span>{" "}
                        <a href="/gorusme-ayarla" className="font-bold link">
                          Buradan
                        </a>{" "}
                        Hemen İletişime geçin.
                      </p>
                    </div>
                    {/* <div className="flex lg:w-1/2 justify-center items-center">
                        <img alt="görsel" loading='lazy' className=" w-auto rounded-box" src={img1} />
                      </div> */}
                  </div>
                </div>
              </div>

              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "5000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 flex-col lg:flex-row">
                    <div className="flex lg:w-1/2 justify-center items-center">
                      <img
                        alt="görsel"
                        loading="lazy"
                        className=" w-auto rounded-box"
                        src={img2}
                      />
                    </div>
                    <div className="flex flex-col justify-center lg:w-1/2 gap-8">
                      <h1 className="text-2xl font-bold">
                        Danışmanlık Hizmetlerinin Kapsamı ve Amacı
                      </h1>
                      <p>
                        Proses Verimliliği ve Optimizasyonu, işletmelere
                        aşağıdaki konularda destek sağlar:
                      </p>
                      <p className="text-justify">
                        <span className="text-lg font-bold">
                          Süreç Analizi:
                        </span>{" "}
                        Mevcut iş süreçlerinin detaylı analizi ve optimizasyon
                        fırsatlarının belirlenmesi.
                        <br />
                        <br />
                        <span className="text-lg font-bold">
                          Maliyet Azaltma:
                        </span>{" "}
                        İsrafın azaltılması ve kaynak kullanımının optimizasyonu
                        yoluyla maliyetlerin düşürülmesi.
                        <br />
                        <br />
                        <span className="text-lg font-bold">
                          Kalite İyileştirme:
                        </span>{" "}
                        Ürün ve hizmet kalitesinin artırılması için süreçlerde
                        iyileştirmeler yapılması.
                        <br />
                        <br />
                        <span className="text-lg font-bold">
                          Performans İzleme ve Süreç Kontrolü:
                        </span>{" "}
                        Süreçlerin ve performansın sürekli izlenmesi, verimlilik
                        artışını sürdürmek için gerekli ayarlamaların yapılması.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "6000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 flex-col-reverse lg:flex-row">
                    <div className="flex flex-col justify-center lg:w-2/3 gap-2">
                      <p className="text-justify">
                        <span className="text-2xl font-bold">
                          Uygulama ve Yararları
                        </span>
                        <br />
                        <br />
                        Proses Verimliliği ve Optimizasyonu danışmanlığı,
                        işletmelerin daha hızlı ve maliyet etkin operasyonlar
                        yürütmelerine olanak tanır. Bu iyileştirmeler, pazar
                        taleplerine daha çabuk yanıt verme ve müşteri
                        memnuniyetini artırma kapasitesini geliştirir. Ayrıca,
                        operasyonel verimlilik, işletmelerin çevresel etkilerini
                        de azaltır, çünkü enerji kullanımı ve atık üretimi gibi
                        alanlarda iyileştirmeler sağlar.
                      </p>
                    </div>
                    {/* <div className="flex lg:w-1/2 justify-center items-center">
                        <img alt="görsel" loading='lazy' className="w-auto rounded-box" src={img1} />
                      </div> */}
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "6000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 lg:flex-row">
                    <div className="flex flex-col justify-center lg:w-2/3 gap-2">
                      <p className="text-justify">
                        <span className="text-2xl font-bold">
                          Sosyal ve Çevresel Etkiler
                        </span>
                        <br />
                        <br />
                        Proses Verimliliği ve Optimizasyonu, sürdürülebilir iş
                        uygulamalarını teşvik eder ve çevresel
                        sürdürülebilirliğe katkıda bulunur. İşletmelerin daha az
                        kaynak tüketmesi ve daha az atık üretmesi, genel
                        çevresel ayak izini düşürür ve daha yeşil operasyonlar
                        yürütülmesine olanak sağlar.
                        <br />
                        <br />
                        Proses Verimliliği ve Optimizasyonu Danışmanlığı,
                        işletmelerin rekabet avantajı kazanmalarına ve pazar
                        liderliğini sürdürmelerine yardımcı olan kritik bir
                        hizmettir. Bu danışmanlık, işletmelerin operasyonel
                        mükemmelliği elde etmelerine ve sürdürülebilir büyüme
                        hedeflerine ulaşmalarına olanak tanır.
                      </p>
                    </div>
                    {/* <div className="flex lg:w-1/2 justify-center items-center">
                        <img alt="görsel" loading='lazy' className="w-auto rounded-box" src={img1} />
                      </div> */}
                  </div>
                </div>
              </div>
            </div>

            <RightNavigationInovation />
          </div>
        </div>
      </div>
      <div
        className={`flex flex-col px-5 lg:px-20 py-16 items-center gap-5 lg:gap-10 transition-opacity ease-in ${
          isVisible1 ? "opacity-100" : "opacity-0"
        }`}
        style={{ transitionDuration: "1250ms" }}
      >
        <h1 className="text-xl text-center lg:text-4xl font-bold">
          Hizmetlerimizin Size Sunduğu Fırsatlar
        </h1>
        <div className="flex flex-col lg:flex-row justify-between items-center text-white gap-5">
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <BusinessOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Uzmanlık</h1>
            <p>
              Alanında uzman ekibimizle, karbon ayak izi hesaplama ve raporlama
              konusunda kapsamlı hizmet sunuyoruz.
            </p>
          </div>
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <ReceiptLongOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Şeffaflık</h1>
            <p>
              Çevresel performansınızı net bir şekilde ortaya koyarak,
              şeffaflığı ve hesap verilebilirliği artırıyoruz.
            </p>
          </div>
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <LanguageOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Stratejik Planlama</h1>
            <p>
              Emisyon azaltımı için stratejik planlama yaparak,
              sürdürülebilirlik hedeflerinize ulaşmanıza yardımcı oluyoruz.
            </p>
          </div>
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <BeenhereOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Rekabet Avantajı</h1>
            <p>
              Sürdürülebilirlik odaklı çalışmalarınızla, sektörünüzde fark
              yaratmanıza ve rekabet avantajı elde etmenize olanak sağlıyoruz.
            </p>
          </div>
        </div>
      </div>
      <div
        className={`lg:w-9/12 transition-opacity ease-in ${
          isVisible1 ? "opacity-100" : "opacity-0"
        }`}
        style={{ transitionDuration: "1250ms" }}
      >
        <img loading="lazy" src={stageImage} />
      </div>
    </div>
  );
};

const WaterEfficiencyAndOptimization = () => {
  const ref1 = useRef();
  const isVisible1 = useIsVisible(ref1);

  return (
    <div
      ref={ref1}
      className="flex flex-col items-center justify-center text-base leading-7"
    >
      <div className="flex w-full">
        <img alt="rapor" loading="lazy" className="w-full" src={reportImage} />
      </div>
      <div
        className={`text-base lg:text-lg breadcrumbs px-5 lg:px-20 pt-8 pb-8 w-full`}
      >
        <ul>
          <li>
            <a href="/">Anasayfa</a>
          </li>
          <li>
            <a href="/hizmetlerimiz">Hizmetlerimiz</a>
          </li>
          <li>Su Verimliliği ve Optimizasyonu</li>
        </ul>
      </div>
      <div
        className={`flex bg-slate-50 transition-opacity ease-in ${
          isVisible1 ? "opacity-100" : "opacity-0"
        }`}
        style={{ transitionDuration: "1250ms" }}
      >
        <div className="flex flex-col p-5 lg:p-12 gap-10 lg:gap-20">
          <h1 className="text-center text-4xl">
            Su Verimliliği ve Optimizasyonu: Kaynakları Koruma ve Operasyonel
            Verimliliği Artırma
          </h1>

          <div className="flex flex-col-reverse lg:flex-row w-full gap-5">
            <div className="flex flex-col gap-20">
              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "4000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 flex-col-reverse lg:flex-row">
                    <div className="flex flex-col justify-center lg:w-2/3 gap-2">
                      <p className="text-justify">
                        Su Verimliliği ve Optimizasyonu, işletmelerin ve
                        kuruluşların su kullanımını daha etkin yönetmelerine
                        yardımcı olan stratejik bir danışmanlık hizmetidir. Bu
                        hizmet, su kaynaklarının sürdürülebilir kullanımını
                        teşvik ederek, işletmelerin su tüketimini azaltmalarına
                        ve su yönetim sistemlerini iyileştirmelerine olanak
                        tanır. Uzman danışmanlar, su kullanım analizleri yapar,
                        tasarruf fırsatlarını belirler ve su yönetimi
                        stratejileri geliştirir.
                        <br />
                        <br />
                        <span>
                          Bizden{" "}
                          <span className="font-bold">
                            Su Verimliliği ve Optimizasyonu Hizmeti{" "}
                          </span>
                          Almak için
                        </span>{" "}
                        <a href="/gorusme-ayarla" className="font-bold link">
                          Buradan
                        </a>{" "}
                        Hemen İletişime geçin.
                      </p>
                    </div>
                    {/* <div className="flex lg:w-1/2 justify-center items-center">
                          <img alt="görsel" loading='lazy' className=" w-auto rounded-box" src={img1} />
                        </div> */}
                  </div>
                </div>
              </div>

              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "5000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 flex-col lg:flex-row">
                    <div className="flex lg:w-1/2 justify-center items-center">
                      <img
                        alt="görsel"
                        loading="lazy"
                        className=" w-auto rounded-box"
                        src={img2}
                      />
                    </div>
                    <div className="flex flex-col justify-center lg:w-1/2 gap-8">
                      <h1 className="text-2xl font-bold">
                        Danışmanlık Hizmetlerinin Kapsamı ve Amacı
                      </h1>
                      <p>
                        Su Verimliliği ve Optimizasyonu, işletmelere aşağıdaki
                        konularda destek sağlar:
                      </p>
                      <p className="text-justify">
                        <span className="text-lg font-bold">
                          Su Tüketim Analizi:
                        </span>{" "}
                        İşletmelerin mevcut su kullanımını detaylı bir şekilde
                        analiz etme.
                        <br />
                        <br />
                        <span className="text-lg font-bold">
                          İyileştirme Stratejileri:
                        </span>{" "}
                        Su tüketiminin azaltılması için etkili stratejilerin
                        geliştirilmesi.
                        <br />
                        <br />
                        <span className="text-lg font-bold">
                          Sistem Optimizasyonu:
                        </span>{" "}
                        Su yönetimi sistemlerinin ve proseslerinin verimliliğini
                        artırma.
                        <br />
                        <br />
                        <span className="text-lg font-bold">
                          Eğitim ve Farkındalık Programları:
                        </span>{" "}
                        Çalışanların su tasarrufu konusunda bilinçlendirilmesi
                        ve eğitilmesi.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "6000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 flex-col-reverse lg:flex-row">
                    <div className="flex flex-col justify-center lg:w-2/3 gap-2">
                      <p className="text-justify">
                        <span className="text-2xl font-bold">
                          Uygulama ve Yararları
                        </span>
                        <br />
                        <br />
                        Su Verimliliği ve Optimizasyonu danışmanlığı,
                        işletmelerin su maliyetlerini düşürmelerine ve
                        operasyonel verimliliklerini artırmalarına yardımcı
                        olur. Su tüketiminin azaltılması, işletmelerin çevresel
                        izlerini küçültür ve sürdürülebilir kaynak kullanımını
                        teşvik eder. Ayrıca, daha az su tüketimi, çevresel
                        düzenlemelere daha iyi uyum sağlamalarına ve su
                        kısıtlamalarına karşı daha dayanıklı olmalarına olanak
                        tanır.
                      </p>
                    </div>
                    {/* <div className="flex lg:w-1/2 justify-center items-center">
                          <img alt="görsel" loading='lazy' className="w-auto rounded-box" src={img1} />
                        </div> */}
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "6000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 lg:flex-row">
                    <div className="flex flex-col justify-center lg:w-2/3 gap-2">
                      <p className="text-justify">
                        <span className="text-2xl font-bold">
                          Sosyal ve Çevresel Etkiler
                        </span>
                        <br />
                        <br />
                        Su Verimliliği ve Optimizasyonu, suyun daha sorumlu bir
                        şekilde kullanılmasını teşvik ederek önemli çevresel
                        faydalar sağlar. Bu hizmet, su kıtlığı riski olan
                        bölgelerde özellikle değerlidir, çünkü su kaynaklarının
                        korunmasına yardımcı olur ve ekosistemlerin sağlığını
                        destekler.
                        <br />
                        <br />
                        Su Verimliliği ve Optimizasyonu Danışmanlığı,
                        işletmelerin ve kuruluşların su kaynaklarını daha
                        verimli kullanmalarını sağlayarak, hem ekonomik hem de
                        çevresel sürdürülebilirliklerini artırır. Bu hizmet, su
                        yönetimi konusunda stratejik rehberlik ve uygulamalı
                        çözümler sunarak, işletmelerin su verimliliğini ve
                        optimizasyonunu iyileştirmelerine yardımcı olur.
                      </p>
                    </div>
                    {/* <div className="flex lg:w-1/2 justify-center items-center">
                          <img alt="görsel" loading='lazy' className="w-auto rounded-box" src={img1} />
                        </div> */}
                  </div>
                </div>
              </div>
            </div>

            <RightNavigationInovation />
          </div>
        </div>
      </div>
      <div
        className={`flex flex-col px-5 lg:px-20 py-16 items-center gap-5 lg:gap-10 transition-opacity ease-in ${
          isVisible1 ? "opacity-100" : "opacity-0"
        }`}
        style={{ transitionDuration: "1250ms" }}
      >
        <h1 className="text-xl text-center lg:text-4xl font-bold">
          Hizmetlerimizin Size Sunduğu Fırsatlar
        </h1>
        <div className="flex flex-col lg:flex-row justify-between items-center text-white gap-5">
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <BusinessOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Uzmanlık</h1>
            <p>
              Alanında uzman ekibimizle, karbon ayak izi hesaplama ve raporlama
              konusunda kapsamlı hizmet sunuyoruz.
            </p>
          </div>
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <ReceiptLongOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Şeffaflık</h1>
            <p>
              Çevresel performansınızı net bir şekilde ortaya koyarak,
              şeffaflığı ve hesap verilebilirliği artırıyoruz.
            </p>
          </div>
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <LanguageOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Stratejik Planlama</h1>
            <p>
              Emisyon azaltımı için stratejik planlama yaparak,
              sürdürülebilirlik hedeflerinize ulaşmanıza yardımcı oluyoruz.
            </p>
          </div>
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <BeenhereOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Rekabet Avantajı</h1>
            <p>
              Sürdürülebilirlik odaklı çalışmalarınızla, sektörünüzde fark
              yaratmanıza ve rekabet avantajı elde etmenize olanak sağlıyoruz.
            </p>
          </div>
        </div>
      </div>
      <div
        className={`lg:w-9/12 transition-opacity ease-in ${
          isVisible1 ? "opacity-100" : "opacity-0"
        }`}
        style={{ transitionDuration: "1250ms" }}
      >
        <img loading="lazy" src={stageImage} />
      </div>
    </div>
  );
};

const TransportationAndLogisticOptimization = () => {
  const ref1 = useRef();
  const isVisible1 = useIsVisible(ref1);

  return (
    <div
      ref={ref1}
      className="flex flex-col items-center justify-center text-base leading-7"
    >
      <div className="flex w-full">
        <img alt="rapor" loading="lazy" className="w-full" src={reportImage} />
      </div>
      <div
        className={`text-base lg:text-lg breadcrumbs px-5 lg:px-20 pt-8 pb-8 w-full`}
      >
        <ul>
          <li>
            <a href="/">Anasayfa</a>
          </li>
          <li>
            <a href="/hizmetlerimiz">Hizmetlerimiz</a>
          </li>
          <li>Taşımacılık ve Lojistikte Yakıt Verimliliği ve Optimizasyonu</li>
        </ul>
      </div>
      <div
        className={`flex bg-slate-50 transition-opacity ease-in ${
          isVisible1 ? "opacity-100" : "opacity-0"
        }`}
        style={{ transitionDuration: "1250ms" }}
      >
        <div className="flex flex-col p-5 lg:p-12 gap-10 lg:gap-20">
          <h1 className="text-center text-4xl">
            Taşımacılık ve Lojistikte Yakıt Verimliliği ve Optimizasyonu:
            Operasyonel Maliyetleri Azaltma ve Çevre Etkilerini Minimize Etme
          </h1>

          <div className="flex flex-col-reverse lg:flex-row w-full gap-5">
            <div className="flex flex-col gap-20">
              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "4000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 flex-col-reverse lg:flex-row">
                    <div className="flex flex-col justify-center lg:w-2/3 gap-2">
                      <p className="text-justify">
                        Taşımacılık ve Lojistikte Yakıt Verimliliği ve
                        Optimizasyonu, nakliye ve lojistik şirketlerinin yakıt
                        tüketimini azaltarak operasyonel verimliliği
                        artırmalarına ve çevresel ayak izlerini düşürmelerine
                        yardımcı olan bir danışmanlık hizmetidir. Bu hizmet,
                        yakıt tüketimi analizleri, rota optimizasyonu, sürücü
                        eğitimleri ve araç bakım yönetimi gibi çeşitli
                        stratejileri içerir. Uzman danışmanlar, şirketlere daha
                        verimli ve sürdürülebilir taşımacılık çözümleri sunarak
                        maliyet tasarrufu sağlarlar.
                        <br />
                        <br />
                        <span>
                          Bizden{" "}
                          <span className="font-bold">
                            Taşımacılık ve Lojistikte Yakıt Verimliliği ve
                            Optimizasyonu Hizmeti{" "}
                          </span>
                          Almak için
                        </span>{" "}
                        <a href="/gorusme-ayarla" className="font-bold link">
                          Buradan
                        </a>{" "}
                        Hemen İletişime geçin.
                      </p>
                    </div>
                    {/* <div className="flex lg:w-1/2 justify-center items-center">
                            <img alt="görsel" loading='lazy' className=" w-auto rounded-box" src={img1} />
                          </div> */}
                  </div>
                </div>
              </div>

              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "5000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 flex-col lg:flex-row">
                    <div className="flex lg:w-1/2 justify-center items-center">
                      <img
                        alt="görsel"
                        loading="lazy"
                        className=" w-auto rounded-box"
                        src={img2}
                      />
                    </div>
                    <div className="flex flex-col justify-center lg:w-1/2 gap-8">
                      <h1 className="text-2xl font-bold">
                        Danışmanlık Hizmetlerinin Kapsamı ve Amacı
                      </h1>
                      <p>
                        Taşımacılık ve Lojistikte Yakıt Verimliliği ve
                        Optimizasyonu, şirketlere aşağıdaki alanlarda destek
                        sağlar:
                      </p>
                      <p className="text-justify">
                        <span className="text-lg font-bold">
                          Yakıt Tüketim Analizi:
                        </span>{" "}
                        Mevcut yakıt kullanımının detaylı analizi ve iyileştirme
                        fırsatlarının belirlenmesi.
                        <br />
                        <br />
                        <span className="text-lg font-bold">
                          Rota Optimizasyonu:
                        </span>{" "}
                        En verimli rotaların belirlenmesi ve lojistik
                        planlamaların optimize edilmesi.
                        <br />
                        <br />
                        <span className="text-lg font-bold">
                          Sürücü Davranışları:
                        </span>{" "}
                        Yakıt tüketimini azaltacak sürüş teknikleri konusunda
                        sürücü eğitimleri.
                        <br />
                        <br />
                        <span className="text-lg font-bold">
                          Araç Bakımı ve Yönetimi:
                        </span>{" "}
                        Araçların yakıt verimliliğini artıracak şekilde
                        bakımının yapılması ve yönetimi.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "6000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 flex-col-reverse lg:flex-row">
                    <div className="flex flex-col justify-center lg:w-2/3 gap-2">
                      <p className="text-justify">
                        <span className="text-2xl font-bold">
                          Uygulama ve Yararları
                        </span>
                        <br />
                        <br />
                        Taşımacılık ve Lojistikte Yakıt Verimliliği ve
                        Optimizasyonu, yakıt maliyetlerinde önemli oranda
                        tasarruf sağlar ve işletmelerin karbon emisyonlarını
                        azaltmalarına yardımcı olur. Bu hizmetler, lojistik
                        operasyonların çevresel etkilerini minimize ederken,
                        şirketlerin sektördeki rekabetçiliklerini artırır.
                        Ayrıca, düzenleyici gerekliliklere uyumu kolaylaştırır
                        ve şirketlerin sürdürülebilirlik hedeflerine
                        ulaşmalarını destekler.
                      </p>
                    </div>
                    {/* <div className="flex lg:w-1/2 justify-center items-center">
                            <img alt="görsel" loading='lazy' className="w-auto rounded-box" src={img1} />
                          </div> */}
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "6000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 lg:flex-row">
                    <div className="flex flex-col justify-center lg:w-2/3 gap-2">
                      <p className="text-justify">
                        <span className="text-2xl font-bold">
                          Sosyal ve Çevresel Etkiler
                        </span>
                        <br />
                        <br />
                        Taşımacılık ve Lojistikte Yakıt Verimliliği ve
                        Optimizasyonu, küresel iklim değişikliğiyle mücadelede
                        önemli bir rol oynar. Yakıt tüketiminin azaltılması,
                        fosil yakıt bağımlılığını düşürür ve sera gazı
                        emisyonlarını azaltır. Bu süreç, şirketlerin çevresel
                        sürdürülebilirlik standartlarını yükseltir ve toplumda
                        çevre bilincinin artmasına katkıda bulunur.
                        <br />
                        <br />
                        Taşımacılık ve Lojistikte Yakıt Verimliliği ve
                        Optimizasyonu Danışmanlığı, nakliye sektöründe
                        operasyonel mükemmelliği ve çevresel sorumluluğu bir
                        araya getiren stratejik bir hizmettir. Bu hizmet,
                        sektördeki şirketlerin maliyetlerini optimize ederken
                        aynı zamanda çevresel ayak izlerini azaltmalarına
                        yardımcı olur.
                      </p>
                    </div>
                    {/* <div className="flex lg:w-1/2 justify-center items-center">
                            <img alt="görsel" loading='lazy' className="w-auto rounded-box" src={img1} />
                          </div> */}
                  </div>
                </div>
              </div>
            </div>

            <RightNavigationInovation />
          </div>
        </div>
      </div>
      <div
        className={`flex flex-col px-5 lg:px-20 py-16 items-center gap-5 lg:gap-10 transition-opacity ease-in ${
          isVisible1 ? "opacity-100" : "opacity-0"
        }`}
        style={{ transitionDuration: "1250ms" }}
      >
        <h1 className="text-xl text-center lg:text-4xl font-bold">
          Hizmetlerimizin Size Sunduğu Fırsatlar
        </h1>
        <div className="flex flex-col lg:flex-row justify-between items-center text-white gap-5">
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <BusinessOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Uzmanlık</h1>
            <p>
              Alanında uzman ekibimizle, karbon ayak izi hesaplama ve raporlama
              konusunda kapsamlı hizmet sunuyoruz.
            </p>
          </div>
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <ReceiptLongOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Şeffaflık</h1>
            <p>
              Çevresel performansınızı net bir şekilde ortaya koyarak,
              şeffaflığı ve hesap verilebilirliği artırıyoruz.
            </p>
          </div>
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <LanguageOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Stratejik Planlama</h1>
            <p>
              Emisyon azaltımı için stratejik planlama yaparak,
              sürdürülebilirlik hedeflerinize ulaşmanıza yardımcı oluyoruz.
            </p>
          </div>
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <BeenhereOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Rekabet Avantajı</h1>
            <p>
              Sürdürülebilirlik odaklı çalışmalarınızla, sektörünüzde fark
              yaratmanıza ve rekabet avantajı elde etmenize olanak sağlıyoruz.
            </p>
          </div>
        </div>
      </div>
      <div
        className={`lg:w-9/12 transition-opacity ease-in ${
          isVisible1 ? "opacity-100" : "opacity-0"
        }`}
        style={{ transitionDuration: "1250ms" }}
      >
        <img loading="lazy" src={stageImage} />
      </div>
    </div>
  );
};

const EnergyProductionFromWaste = () => {
  const ref1 = useRef();
  const isVisible1 = useIsVisible(ref1);

  return (
    <div
      ref={ref1}
      className="flex flex-col items-center justify-center text-base leading-7"
    >
      <div className="flex w-full">
        <img alt="rapor" loading="lazy" className="w-full" src={reportImage} />
      </div>
      <div
        className={`text-base lg:text-lg breadcrumbs px-5 lg:px-20 pt-8 pb-8 w-full`}
      >
        <ul>
          <li>
            <a href="/">Anasayfa</a>
          </li>
          <li>
            <a href="/hizmetlerimiz">Hizmetlerimiz</a>
          </li>
          <li>Atıktan Enerji Üretimi</li>
        </ul>
      </div>
      <div
        className={`flex bg-slate-50 transition-opacity ease-in ${
          isVisible1 ? "opacity-100" : "opacity-0"
        }`}
        style={{ transitionDuration: "1250ms" }}
      >
        <div className="flex flex-col p-5 lg:p-12 gap-10 lg:gap-20">
          <h1 className="text-center text-4xl">
            Atıktan Enerji Üretimi: Sürdürülebilir Enerji Çözümleriyle Atıkları
            Değerlendirme
          </h1>

          <div className="flex flex-col-reverse lg:flex-row w-full gap-5">
            <div className="flex flex-col gap-20">
              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "4000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 flex-col-reverse lg:flex-row">
                    <div className="flex flex-col justify-center lg:w-2/3 gap-2">
                      <p className="text-justify">
                        Atıktan Enerji Üretimi, atıkların enerjiye
                        dönüştürülmesi sürecini ifade eder ve bu süreç, belediye
                        katı atıkları, endüstriyel atıklar veya biyokütle gibi
                        çeşitli atıkların enerji üretiminde kullanılmasını
                        kapsar. Bu hizmet, atıkların çevreye olan olumsuz
                        etkilerini azaltmayı, atık yönetimini iyileştirmeyi ve
                        sürdürülebilir enerji kaynakları yaratmayı amaçlar.
                        Uzman danışmanlar, bu teknolojinin planlanması,
                        tasarlanması ve uygulanmasında rehberlik ederek, enerji
                        üretimi için en uygun atık dönüşüm yöntemlerini
                        belirler.
                        <br />
                        <br />
                        <span>
                          Bizden{" "}
                          <span className="font-bold">
                            Atıktan Enerji Üretimi Hizmeti{" "}
                          </span>
                          Almak için
                        </span>{" "}
                        <a href="/gorusme-ayarla" className="font-bold link">
                          Buradan
                        </a>{" "}
                        Hemen İletişime geçin.
                      </p>
                    </div>
                    {/* <div className="flex lg:w-1/2 justify-center items-center">
                              <img alt="görsel" loading='lazy' className=" w-auto rounded-box" src={img1} />
                            </div> */}
                  </div>
                </div>
              </div>

              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "5000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 flex-col lg:flex-row">
                    <div className="flex lg:w-1/2 justify-center items-center">
                      <img
                        alt="görsel"
                        loading="lazy"
                        className=" w-auto rounded-box"
                        src={img2}
                      />
                    </div>
                    <div className="flex flex-col justify-center lg:w-1/2 gap-8">
                      <h1 className="text-2xl font-bold">
                        Danışmanlık Hizmetlerinin Kapsamı ve Amacı
                      </h1>
                      <p>
                        Atıktan Enerji Üretimi danışmanlığı, şirketlere ve
                        belediyelere aşağıdaki konularda destek sağlar:
                      </p>
                      <p className="text-justify">
                        <span className="text-lg font-bold">
                          Atık Analizi ve Seçimi:
                        </span>{" "}
                        Enerji üretiminde kullanılacak atık türlerinin analizi
                        ve seçimi.
                        <br />
                        <br />
                        <span className="text-lg font-bold">
                          Teknoloji Seçimi ve Uygulama:
                        </span>{" "}
                        Atık dönüşüm teknolojilerinin belirlenmesi ve
                        uygulanması, örneğin, yakma, gazlaştırma veya biyodizel
                        üretimi.
                        <br />
                        <br />
                        <span className="text-lg font-bold">
                          Sistem Tasarımı ve Mühendislik:
                        </span>{" "}
                        Atıktan enerji tesislerinin tasarımı ve mühendislik
                        çalışmaları.
                        <br />
                        <br />
                        <span className="text-lg font-bold">
                          Operasyonel Stratejiler ve Sürdürülebilirlik Planları:
                        </span>{" "}
                        Tesisten elde edilen enerjinin etkin kullanımı ve
                        çevresel yönetmeliklere uyum sağlama.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "6000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 flex-col-reverse lg:flex-row">
                    <div className="flex flex-col justify-center lg:w-2/3 gap-2">
                      <p className="text-justify">
                        <span className="text-2xl font-bold">
                          Uygulama ve Yararları
                        </span>
                        <br />
                        <br />
                        Atıktan Enerji Üretimi danışmanlığı, atık yönetim
                        süreçlerini iyileştirir ve atıkların enerjiye
                        dönüştürülmesi yoluyla çevresel sürdürülebilirliği
                        artırır. Bu süreç, katı atık depolama alanlarına olan
                        ihtiyacı azaltır ve yenilenebilir enerji kaynakları
                        yaratır. Ayrıca, enerji üretimi için fosil yakıtların
                        kullanımını azaltarak, karbon ayak izinin düşürülmesine
                        katkıda bulunur.
                      </p>
                    </div>
                    {/* <div className="flex lg:w-1/2 justify-center items-center">
                              <img alt="görsel" loading='lazy' className="w-auto rounded-box" src={img1} />
                            </div> */}
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "6000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 lg:flex-row">
                    <div className="flex flex-col justify-center lg:w-2/3 gap-2">
                      <p className="text-justify">
                        <span className="text-2xl font-bold">
                          Sosyal ve Çevresel Etkiler
                        </span>
                        <br />
                        <br />
                        Atıktan Enerji Üretimi, atıkların çevresel etkilerini
                        azaltarak ve onları değerli enerji kaynaklarına
                        dönüştürerek sürdürülebilir kalkınmayı destekler. Bu
                        hizmet, çevre kirliliğini ve sera gazı emisyonlarını
                        azaltma potansiyeline sahip olup, yerel topluluklar için
                        temiz ve yenilenebilir enerji kaynakları sağlar.
                        <br />
                        <br />
                        Atıktan Enerji Üretimi Danışmanlığı, atıkların enerjiye
                        dönüştürülmesi sürecinde teknik ve stratejik rehberlik
                        sağlayarak, işletmelerin ve belediyelerin çevresel
                        sürdürülebilirlik hedeflerine ulaşmalarına yardımcı
                        olur. Bu hizmet, atık yönetimi ve enerji üretimi
                        konularında sürdürülebilir ve yenilikçi çözümler sunar.
                      </p>
                    </div>
                    {/* <div className="flex lg:w-1/2 justify-center items-center">
                              <img alt="görsel" loading='lazy' className="w-auto rounded-box" src={img1} />
                            </div> */}
                  </div>
                </div>
              </div>
            </div>

            <RightNavigationInovation />
          </div>
        </div>
      </div>
      <div
        className={`flex flex-col px-5 lg:px-20 py-16 items-center gap-5 lg:gap-10 transition-opacity ease-in ${
          isVisible1 ? "opacity-100" : "opacity-0"
        }`}
        style={{ transitionDuration: "1250ms" }}
      >
        <h1 className="text-xl text-center lg:text-4xl font-bold">
          Hizmetlerimizin Size Sunduğu Fırsatlar
        </h1>
        <div className="flex flex-col lg:flex-row justify-between items-center text-white gap-5">
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <BusinessOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Uzmanlık</h1>
            <p>
              Alanında uzman ekibimizle, karbon ayak izi hesaplama ve raporlama
              konusunda kapsamlı hizmet sunuyoruz.
            </p>
          </div>
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <ReceiptLongOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Şeffaflık</h1>
            <p>
              Çevresel performansınızı net bir şekilde ortaya koyarak,
              şeffaflığı ve hesap verilebilirliği artırıyoruz.
            </p>
          </div>
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <LanguageOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Stratejik Planlama</h1>
            <p>
              Emisyon azaltımı için stratejik planlama yaparak,
              sürdürülebilirlik hedeflerinize ulaşmanıza yardımcı oluyoruz.
            </p>
          </div>
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <BeenhereOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Rekabet Avantajı</h1>
            <p>
              Sürdürülebilirlik odaklı çalışmalarınızla, sektörünüzde fark
              yaratmanıza ve rekabet avantajı elde etmenize olanak sağlıyoruz.
            </p>
          </div>
        </div>
      </div>
      <div
        className={`lg:w-9/12 transition-opacity ease-in ${
          isVisible1 ? "opacity-100" : "opacity-0"
        }`}
        style={{ transitionDuration: "1250ms" }}
      >
        <img loading="lazy" src={stageImage} />
      </div>
    </div>
  );
};

const CircularEconomyPotential = () => {
  const ref1 = useRef();
  const isVisible1 = useIsVisible(ref1);

  return (
    <div
      ref={ref1}
      className="flex flex-col items-center justify-center text-base leading-7"
    >
      <div className="flex w-full">
        <img alt="rapor" loading="lazy" className="w-full" src={reportImage} />
      </div>
      <div
        className={`text-base lg:text-lg breadcrumbs px-5 lg:px-20 pt-8 pb-8 w-full`}
      >
        <ul>
          <li>
            <a href="/">Anasayfa</a>
          </li>
          <li>
            <a href="/hizmetlerimiz">Hizmetlerimiz</a>
          </li>
          <li>Döngüsel Ekonomi Potansiyelinin Belirlenmesi</li>
        </ul>
      </div>
      <div
        className={`flex bg-slate-50 transition-opacity ease-in ${
          isVisible1 ? "opacity-100" : "opacity-0"
        }`}
        style={{ transitionDuration: "1250ms" }}
      >
        <div className="flex flex-col p-5 lg:p-12 gap-10 lg:gap-20">
          <h1 className="text-center text-4xl">
            Döngüsel Ekonomi Potansiyelinin Belirlenmesi: Kaynakların
            Sürdürülebilir Kullanımını Maksimize Etme
          </h1>

          <div className="flex flex-col-reverse lg:flex-row w-full gap-5">
            <div className="flex flex-col gap-20">
              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "4000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 flex-col-reverse lg:flex-row">
                    <div className="flex flex-col justify-center lg:w-2/3 gap-2">
                      <p className="text-justify">
                        Döngüsel Ekonomi Potansiyelinin Belirlenmesi,
                        işletmelerin ve kuruluşların kaynaklarını daha verimli
                        kullanmalarını ve atıkları minimuma indirgemelerini
                        sağlayacak stratejiler geliştirmelerine yardımcı olan
                        bir danışmanlık hizmetidir. Bu hizmet, ürün yaşam
                        döngülerinin analizini, kaynak akışlarının
                        optimizasyonunu ve atık dönüşüm fırsatlarının
                        değerlendirilmesini içerir. Danışmanlar, işletmelerin
                        sürdürülebilir büyüme hedeflerine ulaşmalarına ve
                        döngüsel ekonomi ilkelerini iş modellerine entegre
                        etmelerine yardımcı olur.
                        <br />
                        <br />
                        <span>
                          Bizden{" "}
                          <span className="font-bold">
                            Döngüsel Ekonomi Potansiyelinin Belirlenmesi Hizmeti{" "}
                          </span>
                          Almak için
                        </span>{" "}
                        <a href="/gorusme-ayarla" className="font-bold link">
                          Buradan
                        </a>{" "}
                        Hemen İletişime geçin.
                      </p>
                    </div>
                    {/* <div className="flex lg:w-1/2 justify-center items-center">
                              <img alt="görsel" loading='lazy' className=" w-auto rounded-box" src={img1} />
                            </div> */}
                  </div>
                </div>
              </div>

              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "5000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 flex-col lg:flex-row">
                    <div className="flex lg:w-1/2 justify-center items-center">
                      <img
                        alt="görsel"
                        loading="lazy"
                        className=" w-auto rounded-box"
                        src={img2}
                      />
                    </div>
                    <div className="flex flex-col justify-center lg:w-1/2 gap-8">
                      <h1 className="text-2xl font-bold">
                        Danışmanlık Hizmetlerinin Kapsamı ve Amacı
                      </h1>
                      <p>
                        Döngüsel Ekonomi Potansiyelinin Belirlenmesi, şirketlere
                        aşağıdaki alanlarda destek sağlar:
                      </p>
                      <p className="text-justify">
                        <span className="text-lg font-bold">
                          Kaynak Kullanımı Analizi:
                        </span>{" "}
                        Mevcut kaynak kullanımının ve atık yönetiminin detaylı
                        incelenmesi.
                        <br />
                        <br />
                        <span className="text-lg font-bold">
                          Sürdürülebilirlik Stratejileri:
                        </span>{" "}
                        Atıkların azaltılması, yeniden kullanımı ve geri
                        dönüşümü için stratejilerin geliştirilmesi.
                        <br />
                        <br />
                        <span className="text-lg font-bold">
                          Ürün Tasarımı ve İnovasyon:
                        </span>{" "}
                        Döngüsel ekonomi prensiplerine uygun ürünlerin
                        tasarlanması.
                        <br />
                        <br />
                        <span className="text-lg font-bold">
                          Tedarik Zinciri Yönetimi:
                        </span>{" "}
                        Tedarik zincirinin her aşamasında kaynak verimliliğinin
                        artırılması.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "6000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 flex-col-reverse lg:flex-row">
                    <div className="flex flex-col justify-center lg:w-2/3 gap-2">
                      <p className="text-justify">
                        <span className="text-2xl font-bold">
                          Uygulama ve Yararları
                        </span>
                        <br />
                        <br />
                        Döngüsel Ekonomi Potansiyelinin Belirlenmesi
                        danışmanlığı, işletmelerin operasyonel ve maliyet
                        verimliliğini artırırken çevresel ayak izlerini
                        azaltmalarına yardımcı olur. Bu süreç, uzun vadeli
                        sürdürülebilirlik ve ekonomik kararlılık sağlar ve
                        işletmelere yeni pazar fırsatları sunar. Ayrıca,
                        döngüsel ekonomi uygulamaları, tüketici taleplerine
                        yanıt vermek ve yeşil regülasyonlara uyum sağlamak için
                        de kritik öneme sahiptir.
                      </p>
                    </div>
                    {/* <div className="flex lg:w-1/2 justify-center items-center">
                              <img alt="görsel" loading='lazy' className="w-auto rounded-box" src={img1} />
                            </div> */}
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "6000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 lg:flex-row">
                    <div className="flex flex-col justify-center lg:w-2/3 gap-2">
                      <p className="text-justify">
                        <span className="text-2xl font-bold">
                          Sosyal ve Çevresel Etkiler
                        </span>
                        <br />
                        <br />
                        Döngüsel Ekonomi Potansiyelinin Belirlenmesi, kaynak
                        tüketiminin azaltılmasına ve atık miktarının
                        minimizasyonuna büyük katkı sağlar. Bu hizmet, çevresel
                        sürdürülebilirliği artırır ve doğal kaynakların
                        korunmasına yardımcı olur. İşletmelerin çevre üzerindeki
                        etkilerini azaltması, toplumsal refah ve çevresel sağlık
                        için de olumlu sonuçlar doğurur.
                        <br />
                        <br />
                        Döngüsel Ekonomi Potansiyelinin Belirlenmesi
                        Danışmanlığı, işletmelerin ve kuruluşların kaynakları
                        daha verimli kullanmalarını sağlayarak ekonomik ve
                        çevresel sürdürülebilirliği bir arada sunar. Bu hizmet,
                        işletmelerin çevre dostu ve yenilikçi iş uygulamalarını
                        benimsemelerine olanak tanıyarak, daha yeşil ve daha
                        etkili bir ekonomik yapı oluşturulmasına katkıda
                        bulunur.
                      </p>
                    </div>
                    {/* <div className="flex lg:w-1/2 justify-center items-center">
                              <img alt="görsel" loading='lazy' className="w-auto rounded-box" src={img1} />
                            </div> */}
                  </div>
                </div>
              </div>
            </div>

            <RightNavigationInovation />
          </div>
        </div>
      </div>
      <div
        className={`flex flex-col px-5 lg:px-20 py-16 items-center gap-5 lg:gap-10 transition-opacity ease-in ${
          isVisible1 ? "opacity-100" : "opacity-0"
        }`}
        style={{ transitionDuration: "1250ms" }}
      >
        <h1 className="text-xl text-center lg:text-4xl font-bold">
          Hizmetlerimizin Size Sunduğu Fırsatlar
        </h1>
        <div className="flex flex-col lg:flex-row justify-between items-center text-white gap-5">
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <BusinessOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Uzmanlık</h1>
            <p>
              Alanında uzman ekibimizle, karbon ayak izi hesaplama ve raporlama
              konusunda kapsamlı hizmet sunuyoruz.
            </p>
          </div>
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <ReceiptLongOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Şeffaflık</h1>
            <p>
              Çevresel performansınızı net bir şekilde ortaya koyarak,
              şeffaflığı ve hesap verilebilirliği artırıyoruz.
            </p>
          </div>
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <LanguageOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Stratejik Planlama</h1>
            <p>
              Emisyon azaltımı için stratejik planlama yaparak,
              sürdürülebilirlik hedeflerinize ulaşmanıza yardımcı oluyoruz.
            </p>
          </div>
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <BeenhereOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Rekabet Avantajı</h1>
            <p>
              Sürdürülebilirlik odaklı çalışmalarınızla, sektörünüzde fark
              yaratmanıza ve rekabet avantajı elde etmenize olanak sağlıyoruz.
            </p>
          </div>
        </div>
      </div>
      <div
        className={`lg:w-9/12 transition-opacity ease-in ${
          isVisible1 ? "opacity-100" : "opacity-0"
        }`}
        style={{ transitionDuration: "1250ms" }}
      >
        <img loading="lazy" src={stageImage} />
      </div>
    </div>
  );
};

const PollutionPreventionAnalysis = () => {
  const ref1 = useRef();
  const isVisible1 = useIsVisible(ref1);

  return (
    <div
      ref={ref1}
      className="flex flex-col items-center justify-center text-base leading-7"
    >
      <div className="flex w-full">
        <img alt="rapor" loading="lazy" className="w-full" src={reportImage} />
      </div>
      <div
        className={`text-base lg:text-lg breadcrumbs px-5 lg:px-20 pt-8 pb-8 w-full`}
      >
        <ul>
          <li>
            <a href="/">Anasayfa</a>
          </li>
          <li>
            <a href="/hizmetlerimiz">Hizmetlerimiz</a>
          </li>
          <li>Kirlilik Önlemenin Analizi</li>
        </ul>
      </div>
      <div
        className={`flex bg-slate-50 transition-opacity ease-in ${
          isVisible1 ? "opacity-100" : "opacity-0"
        }`}
        style={{ transitionDuration: "1250ms" }}
      >
        <div className="flex flex-col p-5 lg:p-12 gap-10 lg:gap-20">
          <h1 className="text-center text-4xl">
            Kirlilik Önlemenin Analizi: Çevresel Etkileri Azaltma Yöntemleri
          </h1>

          <div className="flex flex-col-reverse lg:flex-row w-full gap-5">
            <div className="flex flex-col gap-20">
              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "4000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 flex-col-reverse lg:flex-row">
                    <div className="flex flex-col justify-center lg:w-2/3 gap-2">
                      <p className="text-justify">
                        Kirlilik Önlemenin Analizi, işletmelerin çevresel
                        etkilerini en aza indirmek için kirlilik kaynaklarını
                        tespit etmelerine ve etkili önleme stratejileri
                        geliştirmelerine yardımcı olan bir danışmanlık
                        hizmetidir. Bu hizmet, atık yönetimi, emisyon kontrolü
                        ve süreç iyileştirme gibi konularda derinlemesine
                        değerlendirmeler sunar. Uzman danışmanlar, işletmelerin
                        sürdürülebilir operasyonlar yürütmesine ve çevresel uyum
                        sağlamasına destek olur.
                        <br />
                        <br />
                        <span>
                          Bizden{" "}
                          <span className="font-bold">
                            Kirlilik Önlemenin Analizi Hizmeti{" "}
                          </span>
                          Almak için
                        </span>{" "}
                        <a href="/gorusme-ayarla" className="font-bold link">
                          Buradan
                        </a>{" "}
                        Hemen İletişime geçin.
                      </p>
                    </div>
                    {/* <div className="flex lg:w-1/2 justify-center items-center">
                                <img alt="görsel" loading='lazy' className=" w-auto rounded-box" src={img1} />
                              </div> */}
                  </div>
                </div>
              </div>

              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "5000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 flex-col lg:flex-row">
                    <div className="flex lg:w-1/2 justify-center items-center">
                      <img
                        alt="görsel"
                        loading="lazy"
                        className=" w-auto rounded-box"
                        src={img2}
                      />
                    </div>
                    <div className="flex flex-col justify-center lg:w-1/2 gap-8">
                      <h1 className="text-2xl font-bold">
                        Danışmanlık Hizmetlerinin Kapsamı ve Amacı
                      </h1>
                      <p>
                        Kirlilik Önlemenin Analizi, işletmelere aşağıdaki
                        konularda destek sağlar:
                      </p>
                      <p className="text-justify">
                        <span className="text-lg font-bold">
                          Kirlilik Kaynaklarının Tespiti:
                        </span>{" "}
                        İşletmelerin operasyonlarındaki potansiyel kirlilik
                        kaynaklarını belirleme.
                        <br />
                        <br />
                        <span className="text-lg font-bold">
                          Risk Değerlendirmesi:
                        </span>{" "}
                        Çevresel riskleri değerlendirme ve önceliklendirme.
                        <br />
                        <br />
                        <span className="text-lg font-bold">
                          Önleme Stratejileri Geliştirme:
                        </span>{" "}
                        Kirliliği kaynağında önlemek için stratejilerin ve
                        çözümlerin tasarlanması.
                        <br />
                        <br />
                        <span className="text-lg font-bold">
                          Süreç ve Teknoloji Optimizasyonu:
                        </span>{" "}
                        Daha az kirlilik üreten teknolojilerin ve süreçlerin
                        uygulanması.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "6000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 flex-col-reverse lg:flex-row">
                    <div className="flex flex-col justify-center lg:w-2/3 gap-2">
                      <p className="text-justify">
                        <span className="text-2xl font-bold">
                          Uygulama ve Yararları
                        </span>
                        <br />
                        <br />
                        Kirlilik Önlemenin Analizi, işletmelerin çevresel
                        regülasyonlara uyumunu kolaylaştırır ve potansiyel cezai
                        yükümlülükleri azaltır. Kirlilik önleme stratejilerinin
                        uygulanması, işletmelerin operasyonel maliyetlerini
                        düşürebilir ve kamuoyu nezdinde itibarlarını
                        güçlendirebilir. Ayrıca, bu stratejiler
                        sürdürülebilirlik raporlaması ve yeşil pazarlama
                        inisiyatiflerinde de değerli bir araçtır.
                      </p>
                    </div>
                    {/* <div className="flex lg:w-1/2 justify-center items-center">
                                <img alt="görsel" loading='lazy' className="w-auto rounded-box" src={img1} />
                              </div> */}
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "6000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 lg:flex-row">
                    <div className="flex flex-col justify-center lg:w-2/3 gap-2">
                      <p className="text-justify">
                        <span className="text-2xl font-bold">
                          Sosyal ve Çevresel Etkiler
                        </span>
                        <br />
                        <br />
                        Kirlilik Önlemenin Analizi, çevresel sürdürülebilirlik
                        yönünden önemli katkılar sağlar. İşletmelerin çevresel
                        ayak izlerini azaltması, doğal kaynakların korunmasına
                        ve ekosistem sağlığının iyileştirilmesine yardımcı olur.
                        Bu yaklaşım, toplumun genel sağlığını koruyarak çevresel
                        adaleti destekler ve daha temiz bir çevre oluşturur.
                        <br />
                        <br />
                        Kirlilik Önlemenin Analizi Danışmanlığı, işletmelerin
                        çevresel etkilerini azaltma ve daha sürdürülebilir
                        operasyonlar yürütme yolculuklarında kritik bir
                        rehberdir. Bu hizmet, işletmelere çevresel
                        performanslarını iyileştirme ve kirlilik önleme
                        konusunda stratejik destek sunar.
                      </p>
                    </div>
                    {/* <div className="flex lg:w-1/2 justify-center items-center">
                                <img alt="görsel" loading='lazy' className="w-auto rounded-box" src={img1} />
                              </div> */}
                  </div>
                </div>
              </div>
            </div>

            <RightNavigationInovation />
          </div>
        </div>
      </div>
      <div
        className={`flex flex-col px-5 lg:px-20 py-16 items-center gap-5 lg:gap-10 transition-opacity ease-in ${
          isVisible1 ? "opacity-100" : "opacity-0"
        }`}
        style={{ transitionDuration: "1250ms" }}
      >
        <h1 className="text-xl text-center lg:text-4xl font-bold">
          Hizmetlerimizin Size Sunduğu Fırsatlar
        </h1>
        <div className="flex flex-col lg:flex-row justify-between items-center text-white gap-5">
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <BusinessOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Uzmanlık</h1>
            <p>
              Alanında uzman ekibimizle, karbon ayak izi hesaplama ve raporlama
              konusunda kapsamlı hizmet sunuyoruz.
            </p>
          </div>
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <ReceiptLongOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Şeffaflık</h1>
            <p>
              Çevresel performansınızı net bir şekilde ortaya koyarak,
              şeffaflığı ve hesap verilebilirliği artırıyoruz.
            </p>
          </div>
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <LanguageOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Stratejik Planlama</h1>
            <p>
              Emisyon azaltımı için stratejik planlama yaparak,
              sürdürülebilirlik hedeflerinize ulaşmanıza yardımcı oluyoruz.
            </p>
          </div>
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <BeenhereOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Rekabet Avantajı</h1>
            <p>
              Sürdürülebilirlik odaklı çalışmalarınızla, sektörünüzde fark
              yaratmanıza ve rekabet avantajı elde etmenize olanak sağlıyoruz.
            </p>
          </div>
        </div>
      </div>
      <div
        className={`lg:w-9/12 transition-opacity ease-in ${
          isVisible1 ? "opacity-100" : "opacity-0"
        }`}
        style={{ transitionDuration: "1250ms" }}
      >
        <img loading="lazy" src={stageImage} />
      </div>
    </div>
  );
};

const ReuseOfTreatedWastewater = () => {
  const ref1 = useRef();
  const isVisible1 = useIsVisible(ref1);

  return (
    <div
      ref={ref1}
      className="flex flex-col items-center justify-center text-base leading-7"
    >
      <div className="flex w-full">
        <img alt="rapor" loading="lazy" className="w-full" src={reportImage} />
      </div>
      <div
        className={`text-base lg:text-lg breadcrumbs px-5 lg:px-20 pt-8 pb-8 w-full`}
      >
        <ul>
          <li>
            <a href="/">Anasayfa</a>
          </li>
          <li>
            <a href="/hizmetlerimiz">Hizmetlerimiz</a>
          </li>
          <li>Arıtılmış Atık Suların Yeniden Kullanılması</li>
        </ul>
      </div>
      <div
        className={`flex bg-slate-50 transition-opacity ease-in ${
          isVisible1 ? "opacity-100" : "opacity-0"
        }`}
        style={{ transitionDuration: "1250ms" }}
      >
        <div className="flex flex-col p-5 lg:p-12 gap-10 lg:gap-20">
          <h1 className="text-center text-4xl">
            Arıtılmış Atık Suların Yeniden Kullanılması: Sürdürülebilir Su
            Yönetimi Çözümleri
          </h1>

          <div className="flex flex-col-reverse lg:flex-row w-full gap-5">
            <div className="flex flex-col gap-20">
              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "4000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 flex-col-reverse lg:flex-row">
                    <div className="flex flex-col justify-center lg:w-2/3 gap-2">
                      <p className="text-justify">
                        Arıtılmış Atık Suların Yeniden Kullanılması, atık suyun
                        arıtılarak tarımsal sulama, sanayi süreçleri, peyzaj
                        sulaması ve hatta içme suyu kaynağı olarak yeniden
                        kullanılmasını kapsayan sürdürülebilir bir su yönetimi
                        stratejisidir. Bu hizmet, su kaynaklarının verimli
                        kullanımını teşvik ederken, su kıtlığı olan bölgelerde
                        alternatif su kaynakları yaratılmasına yardımcı olur.
                        Uzman danışmanlar, arıtma teknolojilerinin seçimi ve
                        uygulama süreçleri konusunda rehberlik ederek,
                        işletmelerin ve yerel yönetimlerin su yönetimi
                        hedeflerine ulaşmalarını destekler.
                        <br />
                        <br />
                        <span>
                          Bizden{" "}
                          <span className="font-bold">
                            Arıtılmış Atık Suların Yeniden Kullanılması Hizmeti{" "}
                          </span>
                          Almak için
                        </span>{" "}
                        <a href="/gorusme-ayarla" className="font-bold link">
                          Buradan
                        </a>{" "}
                        Hemen İletişime geçin.
                      </p>
                    </div>
                    {/* <div className="flex lg:w-1/2 justify-center items-center">
                                  <img alt="görsel" loading='lazy' className=" w-auto rounded-box" src={img1} />
                                </div> */}
                  </div>
                </div>
              </div>

              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "5000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 flex-col lg:flex-row">
                    <div className="flex lg:w-1/2 justify-center items-center">
                      <img
                        alt="görsel"
                        loading="lazy"
                        className=" w-auto rounded-box"
                        src={img2}
                      />
                    </div>
                    <div className="flex flex-col justify-center lg:w-1/2 gap-8">
                      <h1 className="text-2xl font-bold">
                        Danışmanlık Hizmetlerinin Kapsamı ve Amacı
                      </h1>
                      <p>
                        Arıtılmış Atık Suların Yeniden Kullanılması
                        danışmanlığı, aşağıdaki konularda destek sağlar:
                      </p>
                      <p className="text-justify">
                        <span className="text-lg font-bold">
                          Su Arıtma Teknolojileri:
                        </span>{" "}
                        Çeşitli arıtma teknolojileri arasından en uygun olanının
                        seçilmesi.
                        <br />
                        <br />
                        <span className="text-lg font-bold">
                          Sistem Tasarımı ve Entegrasyonu:
                        </span>{" "}
                        Arıtma sistemlerinin tasarımı ve mevcut altyapıya
                        entegrasyonu.
                        <br />
                        <br />
                        <span className="text-lg font-bold">
                          Kalite Kontrol ve İzleme:
                        </span>{" "}
                        Arıtılmış suyun kalitesinin sürekli olarak izlenmesi ve
                        yönetmeliklere uygunluğun sağlanması.
                        <br />
                        <br />
                        <span className="text-lg font-bold">
                          Eğitim ve Farkındalık Artırma:
                        </span>{" "}
                        Yeniden kullanım projelerinin başarısı için gerekli
                        bilgi ve becerilerin paydaşlara aktarılması.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "6000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 flex-col-reverse lg:flex-row">
                    <div className="flex flex-col justify-center lg:w-2/3 gap-2">
                      <p className="text-justify">
                        <span className="text-2xl font-bold">
                          Uygulama ve Yararları
                        </span>
                        <br />
                        <br />
                        Arıtılmış Atık Suların Yeniden Kullanılması, su
                        kaynaklarını korumak ve su kıtlığına çözüm üretmek için
                        etkili bir yöntemdir. Bu yöntem, su tüketiminin
                        azaltılmasına, atık suyun değerlendirilmesine ve
                        işletmelerin su maliyetlerinin düşürülmesine katkı
                        sağlar. Ayrıca, çevresel düzenlemelere uyumu
                        kolaylaştırır ve işletmelerin sürdürülebilirlik
                        profillerini güçlendirir.
                      </p>
                    </div>
                    {/* <div className="flex lg:w-1/2 justify-center items-center">
                                  <img alt="görsel" loading='lazy' className="w-auto rounded-box" src={img1} />
                                </div> */}
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "6000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 lg:flex-row">
                    <div className="flex flex-col justify-center lg:w-2/3 gap-2">
                      <p className="text-justify">
                        <span className="text-2xl font-bold">
                          Sosyal ve Çevresel Etkiler
                        </span>
                        <br />
                        <br />
                        Arıtılmış Atık Suların Yeniden Kullanılması, suyun
                        yeniden kullanımını teşvik ederek çevresel
                        sürdürülebilirliği artırır. Bu uygulama, doğal su
                        kaynaklarının korunmasına yardımcı olur ve atık suyun
                        çevreye olan olumsuz etkilerini azaltır. Ayrıca,
                        toplumların su güvenliğini artırmak ve ekosistem
                        sağlığını desteklemek için kritik bir role sahiptir.
                        <br />
                        <br />
                        Arıtılmış Atık Suların Yeniden Kullanılması
                        Danışmanlığı, işletmelerin ve toplulukların su yönetimi
                        stratejilerini iyileştirerek daha yeşil ve
                        sürdürülebilir bir geleceğe katkıda bulunur. Bu hizmet,
                        su kaynaklarının daha etkin kullanılması ve çevre
                        üzerindeki baskının azaltılması yönünde stratejik destek
                        sunar.
                      </p>
                    </div>
                    {/* <div className="flex lg:w-1/2 justify-center items-center">
                                  <img alt="görsel" loading='lazy' className="w-auto rounded-box" src={img1} />
                                </div> */}
                  </div>
                </div>
              </div>
            </div>

            <RightNavigationInovation />
          </div>
        </div>
      </div>
      <div
        className={`flex flex-col px-5 lg:px-20 py-16 items-center gap-5 lg:gap-10 transition-opacity ease-in ${
          isVisible1 ? "opacity-100" : "opacity-0"
        }`}
        style={{ transitionDuration: "1250ms" }}
      >
        <h1 className="text-xl text-center lg:text-4xl font-bold">
          Hizmetlerimizin Size Sunduğu Fırsatlar
        </h1>
        <div className="flex flex-col lg:flex-row justify-between items-center text-white gap-5">
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <BusinessOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Uzmanlık</h1>
            <p>
              Alanında uzman ekibimizle, karbon ayak izi hesaplama ve raporlama
              konusunda kapsamlı hizmet sunuyoruz.
            </p>
          </div>
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <ReceiptLongOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Şeffaflık</h1>
            <p>
              Çevresel performansınızı net bir şekilde ortaya koyarak,
              şeffaflığı ve hesap verilebilirliği artırıyoruz.
            </p>
          </div>
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <LanguageOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Stratejik Planlama</h1>
            <p>
              Emisyon azaltımı için stratejik planlama yaparak,
              sürdürülebilirlik hedeflerinize ulaşmanıza yardımcı oluyoruz.
            </p>
          </div>
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <BeenhereOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Rekabet Avantajı</h1>
            <p>
              Sürdürülebilirlik odaklı çalışmalarınızla, sektörünüzde fark
              yaratmanıza ve rekabet avantajı elde etmenize olanak sağlıyoruz.
            </p>
          </div>
        </div>
      </div>
      <div
        className={`lg:w-9/12 transition-opacity ease-in ${
          isVisible1 ? "opacity-100" : "opacity-0"
        }`}
        style={{ transitionDuration: "1250ms" }}
      >
        <img loading="lazy" src={stageImage} />
      </div>
    </div>
  );
};

const FacilitySpesificWastewater = () => {
  const ref1 = useRef();
  const isVisible1 = useIsVisible(ref1);

  return (
    <div
      ref={ref1}
      className="flex flex-col items-center justify-center text-base leading-7"
    >
      <div className="flex w-full">
        <img alt="rapor" loading="lazy" className="w-full" src={reportImage} />
      </div>
      <div
        className={`text-base lg:text-lg breadcrumbs px-5 lg:px-20 pt-8 pb-8 w-full`}
      >
        <ul>
          <li>
            <a href="/">Anasayfa</a>
          </li>
          <li>
            <a href="/hizmetlerimiz">Hizmetlerimiz</a>
          </li>
          <li>Tesise Özgü Atıksu Arıtma Prosesi Tasarımı</li>
        </ul>
      </div>
      <div
        className={`flex bg-slate-50 transition-opacity ease-in ${
          isVisible1 ? "opacity-100" : "opacity-0"
        }`}
        style={{ transitionDuration: "1250ms" }}
      >
        <div className="flex flex-col p-5 lg:p-12 gap-10 lg:gap-20">
          <h1 className="text-center text-4xl">
            Tesise Özgü Atıksu Arıtma Prosesi Tasarımı: Özelleştirilmiş
            Çözümlerle Sürdürülebilir Atık Yönetimi
          </h1>

          <div className="flex flex-col-reverse lg:flex-row w-full gap-5">
            <div className="flex flex-col gap-20">
              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "4000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 flex-col-reverse lg:flex-row">
                    <div className="flex flex-col justify-center lg:w-2/3 gap-2">
                      <p className="text-justify">
                        Tesise Özgü Atıksu Arıtma Prosesi Tasarımı, her tesisin
                        özgün ihtiyaçlarına uygun, etkin ve verimli atıksu
                        arıtma çözümleri geliştirmeyi amaçlayan bir danışmanlık
                        hizmetidir. Bu hizmet, endüstriyel tesislerin ve
                        belediyelerin atıksu arıtma kapasitelerini artırmak,
                        çevresel uyumu sağlamak ve işletme maliyetlerini
                        optimize etmek için özelleştirilmiş arıtma sistemleri
                        sunar. Uzman danışmanlar, atıksu karakteristiklerini
                        analiz eder ve en uygun arıtma teknolojilerini seçer.
                        <br />
                        <br />
                        <span>
                          Bizden{" "}
                          <span className="font-bold">
                            Tesise Özgü Atıksu Arıtma Prosesi Tasarımı Hizmeti{" "}
                          </span>
                          Almak için
                        </span>{" "}
                        <a href="/gorusme-ayarla" className="font-bold link">
                          Buradan
                        </a>{" "}
                        Hemen İletişime geçin.
                      </p>
                    </div>
                    {/* <div className="flex lg:w-1/2 justify-center items-center">
                                    <img alt="görsel" loading='lazy' className=" w-auto rounded-box" src={img1} />
                                  </div> */}
                  </div>
                </div>
              </div>

              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "5000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 flex-col lg:flex-row">
                    <div className="flex lg:w-1/2 justify-center items-center">
                      <img
                        alt="görsel"
                        loading="lazy"
                        className=" w-auto rounded-box"
                        src={img2}
                      />
                    </div>
                    <div className="flex flex-col justify-center lg:w-1/2 gap-8">
                      <h1 className="text-2xl font-bold">
                        Danışmanlık Hizmetlerinin Kapsamı ve Amacı
                      </h1>
                      <p>
                        Tesise Özgü Atıksu Arıtma Prosesi Tasarımı, şu
                        hizmetleri içerir:
                      </p>
                      <p className="text-justify">
                        <span className="text-lg font-bold">
                          Atıksu Değerlendirme:
                        </span>{" "}
                        Atıksuyun kimyasal, biyolojik ve fiziksel özelliklerinin
                        detaylı analizi.
                        <br />
                        <br />
                        <span className="text-lg font-bold">
                          Sistem Tasarımı:
                        </span>{" "}
                        Atıksu arıtma ihtiyaçlarına uygun sistemlerin
                        tasarlanması ve mühendislik planlarının oluşturulması.
                        <br />
                        <br />
                        <span className="text-lg font-bold">
                          Teknoloji Seçimi:
                        </span>{" "}
                        Biyolojik arıtma, kimyasal arıtma, filtrasyon ve
                        gelişmiş oksidasyon gibi teknolojiler arasından seçim
                        yapılması.
                        <br />
                        <br />
                        <span className="text-lg font-bold">
                          Uygulama ve Entegrasyon:
                        </span>{" "}
                        Arıtma sistemlerinin mevcut tesis altyapısına
                        entegrasyonu ve uygulama desteği.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "6000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 flex-col-reverse lg:flex-row">
                    <div className="flex flex-col justify-center lg:w-2/3 gap-2">
                      <p className="text-justify">
                        <span className="text-2xl font-bold">
                          Uygulama ve Yararları
                        </span>
                        <br />
                        <br />
                        Tesise Özgü Atıksu Arıtma Prosesi Tasarımı, atıksu
                        arıtma verimliliğini maksimize eder ve regülasyonlara
                        uyumu sağlar. Bu hizmet, atık yönetim maliyetlerinin
                        düşürülmesine, işletme efektivitesinin artırılmasına ve
                        çevresel sürdürülebilirlik hedeflerine ulaşılmasına
                        yardımcı olur. Ayrıca, arıtılmış suyun yeniden kullanımı
                        gibi sürdürülebilir uygulamaları teşvik eder.
                      </p>
                    </div>
                    {/* <div className="flex lg:w-1/2 justify-center items-center">
                                    <img alt="görsel" loading='lazy' className="w-auto rounded-box" src={img1} />
                                  </div> */}
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div
                  className={`transform -translate-x-full transition ${
                    isVisible1 ? "translate-x-0" : null
                  }`}
                  style={{ transitionDuration: "6000ms" }}
                >
                  <div className="flex h-1/2 lg:gap-10 lg:flex-row">
                    <div className="flex flex-col justify-center lg:w-2/3 gap-2">
                      <p className="text-justify">
                        <span className="text-2xl font-bold">
                          Sosyal ve Çevresel Etkiler
                        </span>
                        <br />
                        <br />
                        Tesise Özgü Atıksu Arıtma Prosesi Tasarımı, çevresel
                        etkileri azaltarak toplum sağlığını ve çevre kalitesini
                        korumaya büyük katkı sağlar. Bu hizmet, su kaynaklarının
                        korunmasına ve atıksu ile ilişkili çevre kirliliğinin
                        önlenmesine yardımcı olur.
                        <br />
                        <br />
                        Tesise Özgü Atıksu Arıtma Prosesi Tasarımı Danışmanlığı,
                        tesislerin atıksu arıtma operasyonlarını özelleştirerek,
                        daha temiz ve daha yeşil bir çevre için etkili çözümler
                        sunar. Bu hizmet, işletmelerin ve belediyelerin çevresel
                        yükümlülüklerini yerine getirmelerine ve sürdürülebilir
                        su yönetimi uygulamalarını benimsemelerine olanak tanır.
                      </p>
                    </div>
                    {/* <div className="flex lg:w-1/2 justify-center items-center">
                                    <img alt="görsel" loading='lazy' className="w-auto rounded-box" src={img1} />
                                  </div> */}
                  </div>
                </div>
              </div>
            </div>

            <RightNavigationInovation />
          </div>
        </div>
      </div>
      <div
        className={`flex flex-col px-5 lg:px-20 py-16 items-center gap-5 lg:gap-10 transition-opacity ease-in ${
          isVisible1 ? "opacity-100" : "opacity-0"
        }`}
        style={{ transitionDuration: "1250ms" }}
      >
        <h1 className="text-xl text-center lg:text-4xl font-bold">
          Hizmetlerimizin Size Sunduğu Fırsatlar
        </h1>
        <div className="flex flex-col lg:flex-row justify-between items-center text-white gap-5">
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <BusinessOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Uzmanlık</h1>
            <p>
              Alanında uzman ekibimizle, karbon ayak izi hesaplama ve raporlama
              konusunda kapsamlı hizmet sunuyoruz.
            </p>
          </div>
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <ReceiptLongOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Şeffaflık</h1>
            <p>
              Çevresel performansınızı net bir şekilde ortaya koyarak,
              şeffaflığı ve hesap verilebilirliği artırıyoruz.
            </p>
          </div>
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <LanguageOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Stratejik Planlama</h1>
            <p>
              Emisyon azaltımı için stratejik planlama yaparak,
              sürdürülebilirlik hedeflerinize ulaşmanıza yardımcı oluyoruz.
            </p>
          </div>
          <div
            className="flex flex-col p-8 rounded-box h-full w-full gap-5 transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ backgroundColor: "#2D2D3F", transitionDuration: "400ms" }}
          >
            <BeenhereOutlinedIcon fontSize="large" />
            <h1 className="text-xl">Rekabet Avantajı</h1>
            <p>
              Sürdürülebilirlik odaklı çalışmalarınızla, sektörünüzde fark
              yaratmanıza ve rekabet avantajı elde etmenize olanak sağlıyoruz.
            </p>
          </div>
        </div>
      </div>
      <div
        className={`lg:w-9/12 transition-opacity ease-in ${
          isVisible1 ? "opacity-100" : "opacity-0"
        }`}
        style={{ transitionDuration: "1250ms" }}
      >
        <img loading="lazy" src={stageImage} />
      </div>
    </div>
  );
};

const InovationDetailPage = () => {
  const { id } = useParams();

  const PickPage = () => {
    switch (id) {
      case "yenilenebilir-enerji-sistemleri-tasarimi":
        return <ReusableEnergySystemDesign />;
      case "proses-verimliligi-ve-optimizasyonu":
        return <ProccessEfficiencyAndOptimization />;
      case "su-verimliligi-ve-optimizasyonu":
        return <WaterEfficiencyAndOptimization />;
      case "tasimacilik-ve-logistikte-yakit-verimliligi-ve-optimizasyonu":
        return <TransportationAndLogisticOptimization />;
      case "atiktan-enerji-uretimi":
        return <EnergyProductionFromWaste />;
      case "dongusel-ekonomi-potansiyelinin-belirlenmesi":
        return <CircularEconomyPotential />;
      case "kirlilik-onlemenin-analizi":
        return <PollutionPreventionAnalysis />;
      case "aritilmis-atik-sularin-yeniden-kullanilmasi":
        return <ReuseOfTreatedWastewater />;
      case "tesise-ozgu-atiksu-aritma-prosesi-tasarimi":
        return <FacilitySpesificWastewater />;
    }
  };

  return <PickPage />;
};

export default InovationDetailPage;
